import  React from "react";
import firestore from "firestore";
import firebase from "firebase/compat";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import sqlFormatter from "sql-formatter";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class DialogIntegrarProveedorDetalleReporteQuery extends React.Component {

constructor (props) {
        super (props)       
}

componentWillReceiveProps = (nextProps) => {
    if(nextProps.rowData != null)
    {
        this.setState({   
          codigo_empresa: nextProps.rowData.codigo_empresa ,
          nombre_empresa: nextProps.rowData.nombre_empresa ,
          codigo_proveedor: nextProps.rowData.codigo_proveedor ,
          nombre_proveedor: nextProps.rowData.nombre_proveedor ,
          query: nextProps.rowData.query ,
          reporte: nextProps.rowData.reporte ,
          tiempo: nextProps.rowData.tiempo ,
          servicio: nextProps.rowData.servicio 
        });
    }else
    {
        this.setState({   
          codigo_empresa: "",
          nombre_empresa: "",
          codigo_proveedor: "",
          nombre_proveedor: "",
          query: "",
          reporte: "",
          tiempo: "",
          servicio: ""
        });

    }
}


 handleChange(e){
        this.setState({
            [e.target.id]: e.target.value
        })
    }

  onSubmit = (e,closeModal) => {
       e.preventDefault();

              const db = firebase.firestore();    
              db.collection('APIIntegracionProveedoresDetalleReportes').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+this.state.reporte).set(
                { 
                   codigo_empresa:this.state.codigo_empresa,
                   nombre_empresa:this.state.nombre_empresa,
                   codigo_proveedor:   this.state.codigo_proveedor,
                   nombre_proveedor: this.state.nombre_proveedor,
                   query : this.state.query,
                   reporte : this.state.reporte,
                   tiempo: this.state.tiempo ,
                   servicio :  this.state.servicio
                }
                ).then(function(result){
                      
              }).catch(function(error)
              {
  
              });  
 
      closeModal();
}

render()
{
  const {modalIsOpenReporte, closeModalReporte,rowData} = this.props;

  const rows = rowData;
  let _showsql = "";

  console.log("<------------------------->");
  console.log(rowData.query );
  console.log("<------------------------->");

  if (typeof rowData.query === 'undefined'){
      console.log(" null value");
  }else 
  {
    _showsql  = sqlFormatter.format(rowData.query);
  }
 

  const styledata = {"font-size": "12px"};

    return(
        <Dialog open={modalIsOpenReporte} onClose={closeModalReporte} aria-labelledby="form-dialog-title">

        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Edicion Query </DialogTitle> 
     
        <DialogContent dividers>

        
        <textarea rows="30" cols="80" id="query"     onKeyUp={this.handleChange.bind(this)}      style={styledata}    
        onChange={this.handleChange.bind(this)}     >
                    {_showsql}
        </textarea>

        </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>
        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>
 
 
          <Button   style={{color :"white"}}   onClick={(e) => this.onSubmit(e,closeModalReporte)} color="primary">
            Guardar
          </Button>
          </td>
          <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
          <Button  style={{color :"#26c6da"}}   onClick={closeModalReporte} color="primary">
            Cerrar
          </Button>

          </td>
          </tr>
          </table>

        </DialogActions>
      </Dialog>
    )
 }
}

export default DialogIntegrarProveedorDetalleReporteQuery;