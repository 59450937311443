import React from "react";
import firebase from "firebase/compat";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';



class DialogSeleccionListaPrecio extends React.Component {

constructor (props) {
        super (props)    
        this.state ={   
          lista_precio : "402"
       };   
}

handleChange(e){
  console.log(e);
  this.setState({
    "lista_precio": e.target.value
  })
}


sendDataSiesa(e,submitModalListaPrecios){
  submitModalListaPrecios(this.state.lista_precio);
}
 
render()
{
const {modalIsOpen, closeModalListaPrecios,isEdited,rowData,isDeleted,submitModalListaPrecios} = this.props;

const rows = rowData;

const styles = { border: '1px solid black', width: 600, color: 'black', padding: 20 };

    return(
        <Dialog open={modalIsOpen} onClose={closeModalListaPrecios} aria-labelledby="form-dialog-title">

        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Selecciona la lista de Precio a Sincronizar con Siesa </DialogTitle> 
     
        <DialogContent dividers>
 
        <FormControl component="fieldset">
        <RadioGroup aria-label="listaprecio" name="lista_precio" value={this.state == null ? "402" : this.state.lista_precio} onChange={this.handleChange.bind(this)}>
          <FormControlLabel value="408" control={<Radio color="primary"  />} label="EMPLEADOS" /> 
          <FormControlLabel value="402" control={<Radio color="primary" />} label="PANALERAS" />
          <FormControlLabel value="405" control={<Radio color="primary" />} label="SUPERETTES" />
          <FormControlLabel value="870" control={<Radio color="primary" />} label="TIENDAS" />
          <FormControlLabel value="401" control={<Radio color="primary" />} label="MINIMERCADOS" />

        </RadioGroup>
      </FormControl>
        
       </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>

        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>

     

          <Button  style={{color :"white"}}   onClick={(e) => this.sendDataSiesa(e,submitModalListaPrecios)} color="primary">
              Guardar
            </Button>
          </td>
     
          <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
              <Button  style={{color :"#26c6da"}}  onClick={closeModalListaPrecios} color="primary">
                Cerrar
              </Button>
          </td>
          </tr>
          </table>
     
        </DialogActions>
      </Dialog>
    )
 }
}

export default DialogSeleccionListaPrecio;