import  React from "react";
import firestore from "firestore";
import firebase from "firebase/compat";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class DialogReporte extends React.Component {

constructor (props) {
        super (props)       
}

componentWillReceiveProps = (nextProps) => {
    var  _fecha_creacion = firebase.firestore.FieldValue.serverTimestamp()

    if(nextProps.rowData != null)
    {
        this.setState({   
            estado :true,
            fecha_creacion: _fecha_creacion,
            fecha_actualizacion : _fecha_creacion,
            codigo: nextProps.rowData.codigo ,
            email: nextProps.rowData.email,
            nombre: nextProps.rowData.nombre,
            responsable: nextProps.rowData.responsable
        });
    }else
    {
        this.setState({   
            estado :true,
            fecha_creacion: _fecha_creacion,
            fecha_actualizacion : _fecha_creacion,
            responsable: "",
            codigo: "",
            email: "",
            nombre: ""
        });
    }
}


 handleChange(e){
        this.setState({
            [e.target.id]: e.target.value
        })
    }

 
 onDelete = (e,closeModal) => {
        e.preventDefault();
        const db = firebase.firestore();    
        
         db.collection('APIProveedores').doc(this.state.codigo).delete().then(function(result)
         {
             closeModal();
         }).catch(function(error)
         {
              closeModal();
         });
  }

  onSubmit = (e,closeModal) => {
       e.preventDefault();
       console.log(this.state);
       const db = firebase.firestore();          
        db.collection('APIProveedores').doc(this.state.codigo).set(this.state).then(function(result){
            closeModal();
        }).catch(function(error)
        {
            closeModal();
        });  
}

render()
{
const {modalIsOpen, closeModal,isEdited,rowData,isDeleted} = this.props;

const rows = rowData;

console.log("DELETED: "+isDeleted);

    return(
        <Dialog open={modalIsOpen} onClose={closeModal} aria-labelledby="form-dialog-title">

        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Respuesta Interfaz Siesa</DialogTitle> )  
     
        <DialogContent dividers>

        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Detalle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow>
    
                <TableCell align="right">{row.f_detalle}</TableCell>
               
              </TableRow>
            ))}
          </TableBody>
        </Table>
       

        </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>
        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>
              <Button   style={{color :"white"}}  onClick={closeModal} color="primary">
                Cerrar
              </Button>
          </td>
          </tr>
          </table>

     
        </DialogActions>
      </Dialog>
    )
 }
}

export default DialogReporte;