import firebase from 'firebase/compat';
import "firebase/auth";


/**
 *  
const config = {
   apiKey: "AIzaSyCgCIPPm7cpVNyKyn1OeXPbAXZa2pH-cO4",
   authDomain: "yuba-5a1ac.firebaseapp.com",
   databaseURL: "https://yuba-5a1ac.firebaseio.com",
   projectId: "yuba-5a1ac",
   storageBucket: "yuba-5a1ac.appspot.com",
   messagingSenderId: "177167562230",
   appId: "1:177167562230:web:de25f0a2691d84774143d7"
};

*/

const config = {
   apiKey: "AIzaSyBkCoMoz2gkWzgbzlTh5GjTP-0T78Q0MVw",
   authDomain: "treinta-ea695.firebaseapp.com",
   projectId: "treinta-ea695",
   storageBucket: "treinta-ea695.appspot.com",
   messagingSenderId: "747978232406",
   appId: "1:747978232406:web:06b553d738a028bb6161f7"
 };

firebase.initializeApp(config);


export default firebase;
