import  React from "react";
import firebase from "firebase/compat";
import MaterialTable from "material-table";
import DialogUsuario from "views/Usuario/DialogUsuario.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";


import DialogSeleccionArchivo from "./ConfiguracionSeleccionPortafolio";



class ConfiguracionAvanzada extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
        data: [] ,
        modalIsOpen: false,
        isEdited: false,
        rowData: {},
        isDeleted: false ,
        codigo_empresa :  props.location.state.codigo_empresa,
        nombre_empresa :  props.location.state.nombre_empresa,
        codigo_proveedor :  props.location.state.codigo_proveedor,
        nombre_proveedor :  props.location.state.nombre_proveedor,
        servicio :  props.location.state.servicio
      };


    
 

       const db = firebase.firestore();
       let citiesRef = db.collection('APIIntegracionProveedoresDetalleConfiguracion').where('codigo_proveedor', '==', props.location.state.codigo_proveedor).where('codigo_empresa', '==', props.location.state.codigo_empresa).where('servicio','==',props.location.state.servicio);
       citiesRef.onSnapshot(snapshot => {
       const _data = snapshot.docs.map(doc => doc.data());

            if(props.location.state.servicio == "listaprecios")
            {
              this.setState({ columns: [
                { title: 'Parametro', field: 'parametro' ,editable :'onAdd'},
                { title: 'Valor', field: 'valor'  },
                { title: 'Descuento', field: 'descuento'  },
                { title: 'Portafolio', field: 'portafolio'  }
                
                       ],
                       data: _data
                  });
            }else
            {
              this.setState({ columns: [
                { title: 'Parametro', field: 'parametro' ,editable :'onAdd'},
                { title: 'Valor', field: 'valor'  }
                       ],
                       data: _data
                  });
            }

  
        }, (error) => {
          console.log(error);
        });
        
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        const storedUsuario = localStorage.getItem("usuario");
        if (storedUsuario === null) {
           window.location.href = '/admin/dashboard'; 
        }
    }

    openModal() {
      this.setState({modalIsOpen: true});
    }
   
    afterOpenModal() {
      // references are now sync'd and can be accessed.
      this.subtitle.style.color = '#f00';
    }
   
    closeModal() {
      this.setState({modalIsOpen: false});
    }   

render(){
 
  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };


  var tituloNombre = ""+this.state.servicio+" - "+this.state.nombre_proveedor+" - "+this.state.nombre_empresa;


return (
            <div>
              <MaterialTable
                  title={tituloNombre} 
                  columns={this.state.columns}
                  data={this.state.data}
                  actions={[{
                    icon: "update",
                    tooltip: "Cargar Archivo",
                    isFreeAction: true,
                    onClick: async event => {
                      this.setState({ modalIsOpen: true });
                    }
                  }]}
                  options={{
                    exportButton: true
                  }}
                  editable={{
                 
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = this.state.data;
                            const db = firebase.firestore();    

                            db.collection('APIIntegracionProveedoresDetalleConfiguracion').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+this.state.servicio+"_"+newData.parametro).set(
                              { 
                                 codigo_empresa : this.state.codigo_empresa,
                                 codigo_proveedor : this.state.codigo_proveedor,
                                 nombre_empresa : this.state.nombre_empresa,
                                 nombre_proveedor : this.state.nombre_proveedor,
                                 servicio : this.state.servicio,  
                                 parametro:newData.parametro,
                                 valor:newData.valor,    
                                 descuento: newData.descuento == null  ? "" : newData.descuento ,
                                 portafolio: newData.portafolio == null ? "" : newData.portafolio
                              }
                              ).then(function(result){
                      
                            }).catch(function(error)
                            {
                
                            });  
            
                            this.setState({ data }, () => resolve());
                          
                          }
                          resolve()
                        }, 1000)
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = this.state.data;
                            const db = firebase.firestore();    
                            db.collection('APIIntegracionProveedoresDetalleConfiguracion').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+this.state.servicio+"_"+newData.parametro).set(
                              { 
                                 codigo_empresa : this.state.codigo_empresa,
                                 codigo_proveedor : this.state.codigo_proveedor,
                                 nombre_empresa : this.state.nombre_empresa,
                                 nombre_proveedor : this.state.nombre_proveedor,
                                 servicio : this.state.servicio,  
                                 parametro:newData.parametro,
                                 valor:newData.valor,
                                 descuento: newData.descuento == null  ? "" : newData.descuento ,
                                 portafolio: newData.portafolio == null ? "" : newData.portafolio

                                }
                              ).then(function(result){
                      
                            }).catch(function(error)
                            {
                
                            });  
                            this.setState({ data }, () => resolve());
                          }
                          resolve()
                        }, 1000)
                      }),

            onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    const data = [...this.state.data];
                    data.splice(data.indexOf(oldData), 1);

                      const db = firebase.firestore();    
                        db.collection('APIIntegracionProveedoresDetalleConfiguracion').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+this.state.servicio+"_"+oldData.parametro).delete().then(function(result)
                        {}).catch(function(error)
                        {});
            }, 600);
          }),
                  }}
                />


                <DialogSeleccionArchivo
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
          isEdited={this.state.isEdited}
          rowData={this.state.rowList}
          isDeleted={this.state.isDeleted}
          listadoEmpresas={this.state.listadoEmpresas}
        />
   
            </div>
        );        
    }
}

export default ConfiguracionAvanzada;
