import React from "react";
import firebase from "firebase/compat";
import MaterialTable from "material-table";
import axios from "axios";
import exportFromJSON from "export-from-json";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DialogIntegrarProveedorDetalle from "views/IntegrarProveedorDetalle/DialogIntegrarProveedorDetalle";
import DialogProveedorDetalleRespuesta from "views/IntegrarProveedorDetalle/DialogIntegrarProveedorDetalleRespuesta";

import DialogIntegrarProveedorDetalleQuery from "./DialogIntegrarProveedorDetalleQuery";

import uuid from "uuid-random";
import https from "https";

import { executeDynamicQuery, FirebaseDynamicEmpresa } from "dbdriverdinamic";

import Build from "@material-ui/icons/Build";
import Delete from "@material-ui/icons/Delete";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Edit from "@material-ui/icons/Edit";
import AssignmentRounded from "@material-ui/icons/AssignmentRounded";
import { TbFileSpreadsheet } from "react-icons/tb";
import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import "devextreme/dist/css/dx.light.css";
import Swal from "sweetalert2";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Export,
  Lookup,
  Editing,
  Item,
  Popup,
  Form,
  Selection,
  SearchPanel,
  Button,
} from "devextreme-react/data-grid";
import Card from "components/Card/Card";

const allowedPageSizes = [5, 10, "Todos"];

const texts = {
  exportAll: "Exportar todos los datos",
  exportSelectedRows: "Exportar filas seleccionadas",
  exportTo: "Export",
};

const texts2 = {
  confirmDeleteMessage: "Estas seguro de eliminar el proveedor?",
  cancelRowChanges: "Cancelar",
  saveRowChanges: "Guardar",
};

let dataGrid;

class IntegrarProveedorDetalle extends React.Component {
  constructor(props) {
    super(props);

    try {
      this.state = {
        data: [],
        modalIsOpen: false,
        modal2IsOpen: false,
        modalIsOpenReporte: false,
        responseServicio: "",
        isEdited: false,
        rowData: {},
        isDeleted: false,
        codigo_proveedor: props.location.state.codigo_proveedor,
        nombre_proveedor: props.location.state.nombre_proveedor,
        codigo_empresa: props.location.state.codigo_empresa,
        nombre_empresa: props.location.state.nombre_empresa,
        listadoServicios: [],
        objtoPass1: [],
        erp: [],
        listaEmpresas: [],
        excelDescargar: [],
      };

      const db = firebase.firestore();

      let citiesRef = db
        .collection("APIIntegracionProveedoresDetalle")
        .where("codigo_proveedor", "==", props.location.state.codigo_proveedor)
        .where("codigo_empresa", "==", props.location.state.codigo_empresa);
      let query = citiesRef.onSnapshot((snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());
        db.collection("APIServicios").onSnapshot(
          (snapshot1) => {
            const _data11 = snapshot1.docs.map((doc1) => doc1.data());

            this.setState({ listadoServicios: _data11 });

            var objtoPass = _data11.reduce(function(acc, cur, i) {
              acc[cur.nombre] = cur.nombre;
              return acc;
            }, {});

            this.setState({ objtoPass1: objtoPass });

            this.setState({
              data: _data,
            });

            db.collection("APIEmpresas").onSnapshot(
              (snapshot) => {
                const _data = snapshot.docs.map((doc) => doc.data());
                this.setState({ listaEmpresas: _data });
                if (this.state.codigo_empresa.length > 0) {
                  let empresa = _data.filter(
                    (e) => e.codigo === this.state.codigo_empresa
                  );
                  let erp = empresa.map((e) => e.erp);
                  this.setState({ erp: erp });
                }
              },
              (error) => {
                console.log(error);
              }
            );
          },
          (error) => {
            console.log(error);
          }
        );
      });

      this.openModal = this.openModal.bind(this);
      this.afterOpenModal = this.afterOpenModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.closeModal2 = this.closeModal2.bind(this);
      this.closeModalReporte = this.closeModalReporte.bind(this);

      const storedUsuario = localStorage.getItem("usuario");
      if (storedUsuario === null) {
        window.location.href = "/admin/dashboard";
      }
    } catch {
      window.location.href = "/admin/dashboard";
    }

    this.onInitialized = this.onInitialized.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  closeModalReporte() {
    this.setState({ modalIsOpenReporte: false });
  }

  closeModal2() {
    this.setState({ modal2IsOpen: false });
  }

  onInitialized(e) {
    dataGrid = e.component;
  }

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    const tituloIntegraciones =
      "  Proveedor : " +
      this.state.nombre_proveedor +
      " - empresa : " +
      this.state.nombre_empresa;

    var those = this;

    const exportarJson = () => {
      const fileName = "Últimos productos";
      const exportType = "xls";
      const data = this.state.excelDescargar;
      exportFromJSON({ data, fileName, exportType });
    };

    let ancho = window.screen.width;

    const renderGridCell = (row) => {
      return (
        <div>
          <Tooltip title="Configuración avanzada">
            <IconButton>
              <Build
                onClick={() => {
                  this.props.history.push({
                    pathname:
                      "/admin/integrarproveedordetalleconfiguracionservicio",
                    state: {
                      codigo_proveedor: row.data.codigo_proveedor,
                      nombre_proveedor: row.data.nombre_proveedor,
                      codigo_empresa: row.data.codigo_empresa,
                      nombre_empresa: row.data.nombre_empresa,
                      servicio: row.data.servicio,
                    },
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "#337ab7",
                }}
              />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip title="Query">
            <IconButton>
              <AssignmentRounded
                onClick={() => {
                  this.setState({
                    rowData: row.data,
                    modalIsOpenReporte: true,
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "#337ab7",
                }}
              />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip title="Descargar">
            <IconButton>
              <TbFileSpreadsheet
                onClick={() => {
                  let empresa = this.state.listaEmpresas.filter(
                    (empresa) => empresa.nombre === row.data.nombre_empresa
                  );
                  let key = empresa[0].token;
                  let token = empresa[0].access_key;

                  axios
                    .get(
                      `https://log.yubapps.com/api/YUBASyncAPI?nombre_coleccion=yubasync&yuba_token=${token}&yuba_key=${key}`
                    )
                    .then((res) => {
                      if (res.data.length > 0) {
                        console.log("ressssssssss",res)
                        this.setState({
                          excelDescargar: JSON.parse(res.data[0].json),
                        });
                        exportarJson();
                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title: "Descaga exitosa",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      } else {
                        Swal.fire({
                          position: "center",
                          icon: "error",
                          title: "No hay información para exportar",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      }
                    });
                }}
                style={{
                  cursor: "pointer",
                  color: "#337ab7",
                }}
              />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip title="Eliminar">
            <IconButton>
              <Delete
                onClick={() => {
                  dataGrid.deleteRow(row.rowIndex);
                }}
                style={{
                  cursor: "pointer",
                  color: "#337ab7",
                }}
              />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip title="Editar">
            <IconButton>
              <Edit
                onClick={() => {
                  dataGrid.editRow(row.rowIndex);
                }}
                style={{
                  cursor: "pointer",
                  color: "#337ab7",
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    };

    let servicio = [];

    for (const servicios in this.state.objtoPass1) {
      servicio.push(servicios);
    }

    let positionEditorOptions1 = {
      items: servicio,
      searchEnabled: true,
      value: "",
    };

    let tiempo = [
      "En Linea",
      "1 Minuto",
      "2 Minutos",
      "3 Minutos",
      "4 Minutos",
      "5 Minutos",
      "10 Minutos",
      "15 Minutos",
      "30 Minutos",
      "45 Minutos",
      "1 Hora",
      "2 Horas",
      "12 Horas",
      "1 Dia",
    ];

    let positionEditorOptions2 = {
      items: tiempo,
      searchEnabled: true,
      value: "",
    };

    let operacion =
      this.state.erp[0] === "SIESA"
        ? ["QUERY", "XML"]
        : this.state.erp[0] === "SAP BUSINESS ONE"
        ? ["QUERY", "SERVICE LAYER", "DI API"]
        : this.state.erp[0] === "SAP R3"
        ? ["RFC", "WEB SERVICE"]
        : [""];

    let positionEditorOptions3 = {
      items: operacion,
      searchEnabled: true,
      value: "",
    };

    console.log("dataaaaaaa", this.state.data, this.state.listaEmpresas)

    return (
      <div>
        <DialogIntegrarProveedorDetalle
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
          isEdited={this.state.isEdited}
          rowData={this.state.rowData}
          isDeleted={this.state.isDeleted}
          listadoEmpresas={this.state.listadoEmpresas}
        />

        <DialogProveedorDetalleRespuesta
          modalIsOpen={this.state.modal2IsOpen}
          closeModal={this.closeModal2}
          responseServicio={this.state.responseServicio}
        />

        <DialogIntegrarProveedorDetalleQuery
          modalIsOpenReporte={this.state.modalIsOpenReporte}
          closeModalReporte={this.closeModalReporte}
          isEdited={this.state.isEdited}
          rowData={this.state.rowData}
        />
        <Grid>
          <Card
            style={{
              padding: 10,
              width: "100%",
            }}
          >
            <Box width="100%">
              <h4
                style={{
                  fontWeight: 500,
                }}
              >
                {tituloIntegraciones.toUpperCase()}
              </h4>
              <DataGrid
                dataSource={this.state.data}
                keyExpr=""
                showColumnLines={true}
                // onRowClick={e => console.log(e)}
                onCellPrepared={(e) => {
                  e.cellElement.classList.add("adaptiveRowStyle");
                }}
                onExporting={(e) => {}}
                showHeaderFilter={true}
                showRowLines={true}
                columnAutoWidth={true}
                showBorders={true}
                onRowRemoved={(row) => {
                  let oldData = row.data;

                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      const data = [...this.state.data];
                      data.splice(data.indexOf(oldData), 1);

                      const db = firebase.firestore();
                      db.collection("APIIntegracionProveedoresDetalle")
                        .doc(
                          this.state.codigo_empresa +
                            "_" +
                            this.state.codigo_proveedor +
                            "_" +
                            oldData.servicio
                        )
                        .delete()
                        .then(function(result) {
                          Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Proveedor eliminado",
                            showConfirmButton: false,
                            timer: 1500,
                          });
                        })
                        .catch(function(error) {});
                    }, 600);
                  });
                }}
                onRowInserted={(row) => {
                  const convertirTiempo = () => {
                    switch (row.data.tiempo) {
                      case "En Linea":
                        return 0;
                        break;
                      case "1 Minuto":
                        return 1;
                        break;
                      case "2 Minutos":
                        return 2;
                        break;
                      case "3 Minutos":
                        return 3;
                        break;
                      case "4 Minutos":
                        return 4;
                        break;
                      case "5 Minutos":
                        return 5;
                        break;
                      case "10 Minutos":
                        return 10;
                        break;
                      case "15 Minutos":
                        return 15;
                        break;
                      case "30 Minutos":
                        return 30;
                        break;
                      case "45 Minutos":
                        return 45;
                        break;
                      case "1 Hora":
                        return 60;
                        break;
                      case "2 Horas":
                        return 120;
                        break;
                      case "12 Horas":
                        return 720;
                        break;
                      case "1 Dia":
                        return 1440;
                        break;

                      default:
                        break;
                    }
                  };

                  let newData = {
                    servicio: row.data.servicio,
                    tiempo: convertirTiempo(),
                    operacion: row.data.operacion,
                  };

                  new Promise(async (resolve) => {
                    const data = [...this.state.data];
                    data.push(newData);
                    console.log(newData);
                    let query = "";
                    let tiempo = "";
                    query = newData.query;
                    tiempo = newData.tiempo;
                    this.state.listadoServicios.forEach(function(element) {
                      if (
                        element.nombre == newData.servicio &&
                        (newData.query == "" ||
                          typeof newData.query === "undefined")
                      ) {
                        query = element.query;
                      }

                      if (
                        element.nombre == newData.servicio &&
                        (newData.tiempo == "" ||
                          typeof newData.tiempo === "undefined")
                      ) {
                        tiempo = element.tiempo;
                      }
                    });

                    const db = firebase.firestore();

                    if (typeof newData.operacion === "undefined") {
                      newData["operacion"] = "consulta";
                    }

                    if (typeof newData.accion === "undefined") {
                      newData["accion"] = "1";
                    }

                    if (typeof newData.url === "undefined") {
                      newData["url"] = "";
                    }

                    if (typeof newData.metodo === "undefined") {
                      newData["metodo"] = "";
                    }

                    if (typeof newData.path === "undefined") {
                      newData["path"] = "";
                    }

                    await db
                      .collection("APIIntegracionProveedoresDetalle")
                      .doc(
                        this.state.codigo_empresa +
                          "_" +
                          this.state.codigo_proveedor +
                          "_" +
                          newData.servicio
                      )
                      .set({
                        codigo_empresa: this.state.codigo_empresa,
                        nombre_empresa: this.state.nombre_empresa,
                        codigo_proveedor: this.state.codigo_proveedor,
                        nombre_proveedor: this.state.nombre_proveedor,
                        query: query,
                        servicio: newData.servicio,
                        tiempo: tiempo,
                        operacion: newData.operacion,
                        accion: newData.accion,
                        url: newData.url,
                        metodo: newData.metodo,
                        path: newData.path,
                      })
                      .then(function(____result) {
                        console.log("resultad");
                        console.log(____result);
                      })
                      .catch(function(error) {
                        console.log(error);
                      });

                    resolve();
                  });
                }}
                onInitialized={this.onInitialized}
                onRowUpdated={(row) => {
                  let newData = row.data;
                  new Promise(async (resolve) => {
                    const data = [...this.state.data];
                    data.push(newData);
                    console.log(newData);
                    let query = "";
                    let tiempo = "";
                    query = newData.query;
                    tiempo = newData.tiempo;
                    this.state.listadoServicios.forEach(function(element) {
                      if (
                        element.nombre == newData.servicio &&
                        (newData.query == "" ||
                          typeof newData.query === "undefined")
                      ) {
                        query = element.query;
                      }

                      if (
                        element.nombre == newData.servicio &&
                        (newData.tiempo == "" ||
                          typeof newData.tiempo === "undefined")
                      ) {
                        tiempo = element.tiempo;
                      }
                    });

                    const db = firebase.firestore();

                    if (typeof newData.operacion === "undefined") {
                      newData["operacion"] = "consulta";
                    }

                    if (typeof newData.accion === "undefined") {
                      newData["accion"] = "1";
                    }

                    if (typeof newData.url === "undefined") {
                      newData["url"] = "";
                    }

                    if (typeof newData.metodo === "undefined") {
                      newData["metodo"] = "";
                    }

                    if (typeof newData.path === "undefined") {
                      newData["path"] = "";
                    }

                    await db
                      .collection("APIIntegracionProveedoresDetalle")
                      .doc(
                        this.state.codigo_empresa +
                          "_" +
                          this.state.codigo_proveedor +
                          "_" +
                          newData.servicio
                      )
                      .set({
                        codigo_empresa: this.state.codigo_empresa,
                        nombre_empresa: this.state.nombre_empresa,
                        codigo_proveedor: this.state.codigo_proveedor,
                        nombre_proveedor: this.state.nombre_proveedor,
                        query: query,
                        servicio: newData.servicio,
                        tiempo: tiempo,
                        operacion: newData.operacion,
                        accion: newData.accion,
                        url: newData.url,
                        metodo: newData.metodo,
                        path: newData.path,
                      })
                      .then(function(____result) {
                        console.log("resultad");
                        console.log(____result);
                        this.setState({ data: this.state.data });
                      })
                      .catch(function(error) {
                        console.log(error);
                      });

                    resolve();
                  });
                }}
                rowAlternationEnabled={true}
              >
                <Editing
                  mode="popup"
                  allowUpdating={false}
                  allowAdding={true}
                  allowDeleting={false}
                  useIcons={true}
                  texts={texts2}
                >
                  <Popup
                    title="LISTADO DE PROVEEDORES"
                    showTitle={true}
                    width={ ancho > 800 ? "700px" : ""}
                    height={ ancho > 800 ? "280px" : ""}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item
                        dataField="servicio"
                        caption="Servicio"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions1}
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="tiempo"
                        caption="Tiempo Actualización"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions2}
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="operacion"
                        caption="Operación"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions3}
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                    </Item>
                  </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <Column
                  caption="Acciones"
                  cellRender={renderGridCell}
                  width={270}
                />
                <Column dataField="servicio" caption="Servicio" />
                <Column dataField="tiempo" caption="Tiempo Actualización" />
                <Column dataField="operacion" caption="Operación" />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode="full"
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  texts={texts}
                />
              </DataGrid>
            </Box>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default IntegrarProveedorDetalle;
