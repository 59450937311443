import React from "react";
import firebase from "firebase/compat";
import MaterialTable from "material-table";
import DialogIntegrarProveedorDetalleReporte from "views/IntegrarProveedorDetalle/DialogIntegrarProveedorDetalleReporte";

import DialogIntegrarProveedorDetalleReporteQuery from "./DialogIntegrarProveedorDetalleReporteQuery";

import uuid from "uuid-random";
import https from "https";

import { executeDynamicQuery, FirebaseDynamicEmpresa } from "dbdriverdinamic";

import Build from "@material-ui/icons/Build";
import Delete from "@material-ui/icons/Delete";
import CloudUpload from "@material-ui/icons/CloudUpload";
import AssignmentRounded from "@material-ui/icons/AssignmentRounded";

import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import "devextreme/dist/css/dx.light.css";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Export,
  Lookup,
  Editing,
  Item,
  Popup,
  Form,
  Selection,
  SearchPanel,
} from "devextreme-react/data-grid";
import Card from "components/Card/Card";

const allowedPageSizes = [5, 10, "Todos"];

const texts = {
  exportAll: "Exportar todos los datos",
  exportSelectedRows: "Exportar filas seleccionadas",
  exportTo: "Export",
};

const texts2 = {
  confirmDeleteMessage: "Estas seguro de eliminar el proveedor?",
};

class IntegrarProveedorDetalleReportes extends React.Component {
  constructor(props) {
    super(props);

    try {
      this.state = {
        data: [],
        modalIsOpen: false,
        modalIsOpenReporte: false,
        isEdited: false,
        rowData: {},
        isDeleted: false,
        codigo_proveedor: props.location.state.codigo_proveedor,
        nombre_proveedor: props.location.state.nombre_proveedor,
        codigo_empresa: props.location.state.codigo_empresa,
        nombre_empresa: props.location.state.nombre_empresa,
        listadoServicios: [],
      };

      const db = firebase.firestore();

      let citiesRef = db
        .collection("APIIntegracionProveedoresDetalleReportes")
        .where("codigo_proveedor", "==", props.location.state.codigo_proveedor)
        .where("codigo_empresa", "==", props.location.state.codigo_empresa);
      let query = citiesRef.onSnapshot((snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());

        db.collection("APIServicios").onSnapshot(
          (snapshot1) => {
            const _data11 = snapshot1.docs.map((doc1) => doc1.data());

            this.setState({ listadoServicios: _data11 });

            var objtoPass = _data11.reduce(function(acc, cur, i) {
              acc[cur.nombre] = cur.nombre;
              return acc;
            }, {});

            this.setState({
              columns: [
                {
                  title: "Reporte",
                  field: "reporte",
                  editable: "onAdd",
                },
                {
                  title: "Servicio",
                  field: "servicio",
                  lookup: objtoPass,
                },
                {
                  title: "Tiempo Actualizacion",
                  field: "tiempo",
                  lookup: {
                    0: "En Linea",
                    1: "1 Minuto",
                    2: "2 Minutos",
                    3: "3 Minutos",
                    4: "4 Minutos",
                    5: "5 Minutos",
                    10: "10 Minutos",
                    15: "15 Minutos",
                    30: "30 Minutos",
                    45: "45 Minutos",
                    60: "1 Hora",
                    120: "2 Horas",
                    720: "12 Horas",
                    1440: "1 Dia",
                  },
                },
              ],
              data: _data,
              actions: [
                {
                  icon: "search",
                  tooltip: "Detalle Reporte",
                  onClick: (event, rowdata) =>
                    this.setState({
                      modalIsOpen: true,
                      isEdited: false,
                      rowData: rowdata,
                      isDeleted: false,
                    }),
                },
                {
                  icon: "input",
                  tooltip: "Agregar Parametros al reporte",
                  onClick: (event, rowdata) =>
                    this.setState({
                      modalIsOpen: true,
                      isEdited: true,
                      rowData: rowdata,
                      isDeleted: false,
                    }),
                },
                {
                  icon: "receipt",
                  tooltip: "query",
                  onClick: (event, rowdata) => {
                    this.setState({
                      rowData: rowdata,
                      modalIsOpenReporte: true,
                    });
                  },
                },
              ],
            });
          },
          (error) => {
            console.log(error);
          }
        );
      });

      this.openModal = this.openModal.bind(this);
      this.afterOpenModal = this.afterOpenModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.closeModalReporte = this.closeModalReporte.bind(this);

      const storedUsuario = localStorage.getItem("usuario");
      if (storedUsuario === null) {
        window.location.href = "/admin/dashboard";
      }
    } catch {
      window.location.href = "/admin/dashboard";
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  closeModalReporte() {
    this.setState({ modalIsOpenReporte: false });
  }

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    const tituloIntegraciones =
      " proveedor : " +
      this.state.nombre_proveedor +
      " - empresa : " +
      this.state.nombre_empresa;

    const renderGridCell = (row) => {
      return (
        <div>
          <Tooltip title="Configurar Exportación">
            <IconButton>
              <CloudUpload
                onClick={() => {
                  this.setState({
                    modalIsOpen: true,
                    isEdited: true,
                    rowData: row.data,
                    isDeleted: false,
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "#337ab7",
                }}
              />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip title="Configuración avanzada">
            <IconButton>
              <Build
                onClick={() => {
                  this.props.history.push({
                    pathname:
                      "/admin/integrarproveedordetalleconfiguracionservicio",
                    state: {
                      codigo_proveedor: row.data.codigo_proveedor,
                      nombre_proveedor: row.data.nombre_proveedor,
                      codigo_empresa: row.data.codigo_empresa,
                      nombre_empresa: row.data.nombre_empresa,
                      servicio: row.data.servicio,
                    },
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "#337ab7",
                }}
              />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip title="Query">
            <IconButton>
              <AssignmentRounded
                onClick={() => {
                  this.setState({
                    rowData: row.data,
                    modalIsOpenReporte: true,
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "#337ab7",
                }}
              />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip title="Eliminar">
            <IconButton>
              <Delete
                onClick={() => {
                  let oldData = row.data;
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      const data = [...this.state.data];
                      data.splice(data.indexOf(oldData), 1);

                      const db = firebase.firestore();
                      db.collection("APIIntegracionProveedoresDetalle")
                        .doc(
                          this.state.codigo_empresa +
                            "_" +
                            this.state.codigo_proveedor +
                            "_" +
                            oldData.servicio
                        )
                        .delete()
                        .then(function(result) {})
                        .catch(function(error) {});
                    }, 600);
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "#337ab7",
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    };

    return (
      <div>
        <MaterialTable
          title={tituloIntegraciones}
          columns={this.state.columns}
          data={this.state.data}
          actions={this.state.actions}
          options={{
            exportButton: true,
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  const data = [...this.state.data];
                  data.push(newData);
                  console.log(newData);
                  let query = "";
                  let tiempo = "";
                  query = newData.query;
                  tiempo = newData.tiempo;

                  const db = firebase.firestore();
                  db.collection("APIIntegracionProveedoresDetalleReportes")
                    .doc(
                      this.state.codigo_empresa +
                        "_" +
                        this.state.codigo_proveedor +
                        "_" +
                        newData.reporte
                    )
                    .set({
                      codigo_empresa: this.state.codigo_empresa,
                      nombre_empresa: this.state.nombre_empresa,
                      codigo_proveedor: this.state.codigo_proveedor,
                      nombre_proveedor: this.state.nombre_proveedor,
                      reporte: newData.reporte,
                      tiempo: tiempo,
                      servicio: newData.servicio,
                    })
                    .then(function(result) {})
                    .catch(function(error) {});

                  // this.setState({ ...this.state, data });
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  const data = [...this.state.data];
                  data.push(newData);
                  console.log(newData);
                  let query = "";
                  let tiempo = "";
                  query = newData.query;
                  tiempo = newData.tiempo;

                  const db = firebase.firestore();
                  db.collection("APIIntegracionProveedoresDetalleReportes")
                    .doc(
                      this.state.codigo_empresa +
                        "_" +
                        this.state.codigo_proveedor +
                        "_" +
                        newData.reporte
                    )
                    .set({
                      codigo_empresa: this.state.codigo_empresa,
                      nombre_empresa: this.state.nombre_empresa,
                      codigo_proveedor: this.state.codigo_proveedor,
                      nombre_proveedor: this.state.nombre_proveedor,
                      query: query,
                      reporte: newData.reporte,
                      tiempo: tiempo,
                      servicio: newData.servicio,
                    })
                    .then(function(result) {})
                    .catch(function(error) {});

                  // this.setState({ ...this.state, data });
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  const data = [...this.state.data];
                  data.splice(data.indexOf(oldData), 1);

                  const db = firebase.firestore();
                  db.collection("APIIntegracionProveedoresDetalleReportes")
                    .doc(
                      this.state.codigo_empresa +
                        "_" +
                        this.state.codigo_proveedor +
                        "_" +
                        oldData.reporte
                    )
                    .delete()
                    .then(function(result) {})
                    .catch(function(error) {});
                }, 600);
              }),
          }}
        />

        <DialogIntegrarProveedorDetalleReporte
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
          isEdited={this.state.isEdited}
          rowData={this.state.rowData}
          isDeleted={this.state.isDeleted}
          listadoEmpresas={this.state.listadoEmpresas}
        />
        <DialogIntegrarProveedorDetalleReporteQuery
          modalIsOpenReporte={this.state.modalIsOpenReporte}
          closeModalReporte={this.closeModalReporte}
          isEdited={this.state.isEdited}
          rowData={this.state.rowData}
        />
        <Grid>
          <Card
            style={{
              padding: 10,
              width: "100%",
            }}
          >
            <Box width="100%">
              <h4
                style={{
                  fontWeight: 500,
                }}
              >
                {tituloIntegraciones.toUpperCase()}
              </h4>
              <DataGrid
                dataSource={this.state.data}
                keyExpr="codigo_proveedor"
                showColumnLines={true}
                // onRowClick={e => console.log(e)}
                onCellPrepared={(e) => {
                  e.cellElement.classList.add("adaptiveRowStyle");
                }}
                onExporting={(e) => {}}
                showHeaderFilter={true}
                showRowLines={true}
                columnAutoWidth={true}
                showBorders={true}
                onRowRemoved={(row) => {}}
                onRowInserted={(row) => {
                  const convertirTiempo = () => {
                    switch (row.data.tiempo) {
                      case "En Linea":
                        return 0;
                        break;
                      case "1 Minuto":
                        return 1;
                        break;
                      case "2 Minutos":
                        return 2;
                        break;
                      case "3 Minutos":
                        return 3;
                        break;
                      case "4 Minutos":
                        return 4;
                        break;
                      case "5 Minutos":
                        return 5;
                        break;
                      case "10 Minutos":
                        return 10;
                        break;
                      case "15 Minutos":
                        return 15;
                        break;
                      case "30 Minutos":
                        return 30;
                        break;
                      case "45 Minutos":
                        return 45;
                        break;
                      case "1 Hora":
                        return 60;
                        break;
                      case "2 Horas":
                        return 120;
                        break;
                      case "12 Horas":
                        return 720;
                        break;
                      case "1 Dia":
                        return 1440;
                        break;

                      default:
                        break;
                    }
                  };

                  let newData = {
                    servicio: row.data.servicio,
                    tiempo: convertirTiempo(),
                    operacion: row.data.operacion,
                  };

                  new Promise(async (resolve) => {
                    const data = [...this.state.data];
                    data.push(newData);
                    console.log(newData);
                    let query = "";
                    let tiempo = "";
                    query = newData.query;
                    tiempo = newData.tiempo;
                    this.state.listadoServicios.forEach(function(element) {
                      if (
                        element.nombre == newData.servicio &&
                        (newData.query == "" ||
                          typeof newData.query === "undefined")
                      ) {
                        query = element.query;
                      }

                      if (
                        element.nombre == newData.servicio &&
                        (newData.tiempo == "" ||
                          typeof newData.tiempo === "undefined")
                      ) {
                        tiempo = element.tiempo;
                      }
                    });

                    const db = firebase.firestore();

                    if (typeof newData.operacion === "undefined") {
                      newData["operacion"] = "consulta";
                    }

                    if (typeof newData.accion === "undefined") {
                      newData["accion"] = "1";
                    }

                    if (typeof newData.url === "undefined") {
                      newData["url"] = "";
                    }

                    if (typeof newData.metodo === "undefined") {
                      newData["metodo"] = "";
                    }

                    if (typeof newData.path === "undefined") {
                      newData["path"] = "";
                    }

                    await db
                      .collection("APIIntegracionProveedoresDetalle")
                      .doc(
                        this.state.codigo_empresa +
                          "_" +
                          this.state.codigo_proveedor +
                          "_" +
                          newData.servicio
                      )
                      .set({
                        codigo_empresa: this.state.codigo_empresa,
                        nombre_empresa: this.state.nombre_empresa,
                        codigo_proveedor: this.state.codigo_proveedor,
                        nombre_proveedor: this.state.nombre_proveedor,
                        query: query,
                        servicio: newData.servicio,
                        tiempo: tiempo,
                        operacion: newData.operacion,
                        accion: newData.accion,
                        url: newData.url,
                        metodo: newData.metodo,
                        path: newData.path,
                      })
                      .then(function(____result) {
                        console.log("resultad");
                        console.log(____result);
                      })
                      .catch(function(error) {
                        console.log(error);
                      });

                    resolve();
                  });
                }}
                onInitialized={() => {}}
                onRowUpdated={(row) => {
                  let newData = row.data;
                  new Promise(async (resolve) => {
                    const data = [...this.state.data];
                    data.push(newData);
                    console.log(newData);
                    let query = "";
                    let tiempo = "";
                    query = newData.query;
                    tiempo = newData.tiempo;
                    this.state.listadoServicios.forEach(function(element) {
                      if (
                        element.nombre == newData.servicio &&
                        (newData.query == "" ||
                          typeof newData.query === "undefined")
                      ) {
                        query = element.query;
                      }

                      if (
                        element.nombre == newData.servicio &&
                        (newData.tiempo == "" ||
                          typeof newData.tiempo === "undefined")
                      ) {
                        tiempo = element.tiempo;
                      }
                    });

                    const db = firebase.firestore();

                    if (typeof newData.operacion === "undefined") {
                      newData["operacion"] = "consulta";
                    }

                    if (typeof newData.accion === "undefined") {
                      newData["accion"] = "1";
                    }

                    if (typeof newData.url === "undefined") {
                      newData["url"] = "";
                    }

                    if (typeof newData.metodo === "undefined") {
                      newData["metodo"] = "";
                    }

                    if (typeof newData.path === "undefined") {
                      newData["path"] = "";
                    }

                    await db
                      .collection("APIIntegracionProveedoresDetalle")
                      .doc(
                        this.state.codigo_empresa +
                          "_" +
                          this.state.codigo_proveedor +
                          "_" +
                          newData.servicio
                      )
                      .set({
                        codigo_empresa: this.state.codigo_empresa,
                        nombre_empresa: this.state.nombre_empresa,
                        codigo_proveedor: this.state.codigo_proveedor,
                        nombre_proveedor: this.state.nombre_proveedor,
                        query: query,
                        servicio: newData.servicio,
                        tiempo: tiempo,
                        operacion: newData.operacion,
                        accion: newData.accion,
                        url: newData.url,
                        metodo: newData.metodo,
                        path: newData.path,
                      })
                      .then(function(____result) {
                        console.log("resultad");
                        console.log(____result);
                      })
                      .catch(function(error) {
                        console.log(error);
                      });

                    resolve();
                  });
                }}
                rowAlternationEnabled={true}
              >
                <Editing
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={false}
                  useIcons={true}
                  texts={texts2}
                >
                  <Popup
                    title="LISTADO DE PROVEEDORES"
                    showTitle={true}
                    width={700}
                    height={280}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      {/* <Item
                        dataField="servicio"
                        caption="Servicio"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions1}
                      />
                      <Item
                        dataField="tiempo"
                        caption="Tiempo Actualización"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions2}
                      />
                      <Item
                        dataField="operacion"
                        caption="Operación"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions3}
                      /> */}
                    </Item>
                  </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <Selection
                  mode="multiple"
                  deferred={true}
                  showCheckBoxesMode="always"
                />

                <Column dataField="reporte" caption="Reporte" />
                <Column dataField="servicio" caption="Servicio" />
                <Column dataField="tiempo" caption="Tiempo Actualización" />

                <Column
                  caption="Acciones"
                  cellRender={renderGridCell}
                  width={220}
                />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode="full"
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  texts={texts}
                />
              </DataGrid>
            </Box>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default IntegrarProveedorDetalleReportes;
