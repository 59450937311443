/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import DashboardPage from "views/Dashboard/Dashboard.js";
import IntegrarProveedorDetalle from "views/IntegrarProveedorDetalle/IntegrarProveedorDetalle.js";
import IntegrarProveedorDetalleReportes from "views/IntegrarProveedorDetalle/IntegrarProveedorDetalleReportes.js";
import IntegrarProveedor from "views/IntegrarProveedor/IntegrarProveedor.js";
import Empresas from "views/Empresa/Empresa.js";
import Usuarios from "views/Usuario/Usuario.js";
import Proveedores from "views/Proveedor/Proveedor.js";
import Reporte from "views/Reporte/Reporte.js";
import MonitorServicio from "views/MonitorServicio/MonitorServicio.js";
import TransaccionesRealizadas from "views/TransaccionesRealiadas/TransaccionesRealizadas.js";
import TransaccionesError from "views/TransaccionesError/TransaccionesError.js";
import ReportesDetalle from "views/Reporte/ReporteDetalle.js";
import Pedidos from "views/Pedidos/Pedidos"

import Servicios from "views/Servicio/Servicio.js";
import ConfiguracionAvanzada from "views/ConfiguracionAvanzada/ConfiguracionAvanzada.js";
import ConfiguracionAvanzadaServicio from "views/ConfiguracionAvanzada/ConfiguracionAvanzadaServicio.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Inicio",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/usuario",
    name: "Usuarios",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Usuarios,
    layout: "/admin"
  },
  {
    path: "/empresa",
    name: "Empresas",
    rtlName: "قائمة الجدول",
    icon: "store",
    component: Empresas,
    layout: "/admin"
  },
  {
    path: "/marketplaces",
    name: "Treinta",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: Proveedores,
    layout: "/admin"
  },
  {
    path: "/servicio",
    name: "Servicios",
    rtlName: "قائمة الجدول",
    icon: "dns",
    component: Servicios,
    layout: "/admin"
  },
  ,
  

  {
    path: "/transaccionesError",
    name: "Transacciones con Novedad",
    rtlName: "قائمة الجدول",
    icon: "dns",
    component: TransaccionesError,
    layout: "/admin"
  },
  {
    path: "/reportes",
    name: "Reportes",
    rtlName: "قائمة الجدول",
    icon: "list",
    component: Reporte,
    layout: "/admin"
  },
  {
    path: "/integrarproveedor",
    name: "",
    rtlName: "قائمة الجدول",
    icon: "",
    component: IntegrarProveedor,
    layout: "/admin"
  },
  {
    path: "/integrarproveedordetalle",
    name: "",
    rtlName: "قائمة الجدول",
    icon: "",
    component: IntegrarProveedorDetalle,
    layout: "/admin"
  },
  {
    path: "/integrarproveedordetallereportes",
    name: "",
    rtlName: "قائمة الجدول",
    icon: "",
    component: IntegrarProveedorDetalleReportes,
    layout: "/admin"
  },
  {
    path: "/integrarproveedordetalleconfiguracion",
    name: "",
    rtlName: "قائمة الجدول",
    icon: "",
    component: ConfiguracionAvanzada,
    layout: "/admin"
  },
  {
    path: "/integrarproveedordetalleconfiguracionservicio",
    name: "",
    rtlName: "قائمة الجدول",
    icon: "",
    component: ConfiguracionAvanzadaServicio,
    layout: "/admin"
  },
  {
    path: "/reportesdetalle",
    name: "ReportesDetalle",
    rtlName: "قائمة الجدول",
    icon: "list",
    component: ReportesDetalle,
    layout: "/admin"
  }
];

export default dashboardRoutes;
