import  React from "react";
import firestore from "firestore";
import firebase from "firebase/compat";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
 
var martginTop  = {marginTop : "20px"};
var styleClass = { width:"100%"};
class DialogEmpresa extends React.Component {

    constructor (props) {
        super (props)       
}

componentWillReceiveProps = (nextProps) => {
    var  _fecha_creacion = firebase.firestore.FieldValue.serverTimestamp()

    if(nextProps.rowData != null)
    {
        this.setState({   
            estado :true,
            motordb : nextProps.rowData.motordb,
            fecha_creacion: _fecha_creacion,
            fecha_actualizacion : _fecha_creacion,
            bd_clave: nextProps.rowData.bd_clave ,
            bd_nombre: nextProps.rowData.bd_nombre,
            bd_servidor: nextProps.rowData.bd_servidor,
            bd_usuario: nextProps.rowData.bd_usuario,
            codigo: nextProps.rowData.codigo,
            email: nextProps.rowData.email,
            nombre: nextProps.rowData.nombre,
            url_servicio: nextProps.rowData.url_servicio
        });
    }else
    {
        this.setState({   
            estado :true,
            fecha_creacion: _fecha_creacion,
            fecha_actualizacion : _fecha_creacion,
            bd_clave: "",
            bd_nombre: "",
            bd_servidor: "",
            bd_usuario: "",
            codigo: "",
            email: "",
            nombre: "",
            url_servicio: "",
            motordb :"sqlserver"
        });
    }
}

handleChangeMotor(e){
  this.setState({
      motordb : e.target.value
  })
}

 handleChange(e){
        this.setState({
            [e.target.id]: e.target.value
        })
    }

 onDelete = (e,closeModal) => {
        e.preventDefault();
        const db = firebase.firestore();    
        
         db.collection('APIEmpresas').doc(this.state.codigo).delete().then(function(result)
         {
             closeModal();
         }).catch(function(error)
         {
              closeModal();
         });
  }

 onSubmit = (e,closeModal) => {
       e.preventDefault();
       const db = firebase.firestore();          
        db.collection('APIEmpresas').doc(this.state.codigo).set(this.state).then(function(result){
            closeModal();
        }).catch(function(error)
        {
            closeModal();
        });  
}




render()
{
const {modalIsOpen, closeModal,isEdited,rowData,isDeleted} = this.props;

console.log("DELETED: "+isDeleted);

    return(
        <Dialog open={modalIsOpen} onClose={closeModal} aria-labelledby="form-dialog-title">

        { isDeleted ?  '' : (
        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Detalle de Empresa</DialogTitle> )  
        }

        <DialogContent dividers>

        {  isDeleted ?  (   <DialogContentText> 
         <strong>    Esta seguro que desea eliminar el registro de la Empresa: {rowData.nombre} </strong>
          </DialogContentText> ) : ( <DialogContentText> 
         <strong>    Favor diligencia correctamente los campos para  garantizar el funcionamiento de la integracion. </strong>
        </DialogContentText>
          )
        }

{  isDeleted ?  '' : (  

  
  rowData != null && isEdited ? (
         <TextField
            autoFocus
            disabled 
            margin="dense"
            id="codigo"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="Codigo"
            type="text"
            defaultValue={rowData != null ? rowData.codigo : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
        )   :
        (
          <TextField
          autoFocus
          margin="dense"
          id="codigo"
          onKeyUp={this.handleChange.bind(this)}         
          onChange={this.handleChange.bind(this)}        
          label="Codigo"
          type="text"
          defaultValue={rowData != null ? rowData.codigo : ""}
          fullWidth
          InputProps={{
              readOnly: isEdited ? false : true,
            }}
        />

        )


      )
   }

{  isDeleted ?  '' : (  
         <TextField
            required
            margin="dense"
            id="nombre"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="Nombre"
            type="text"
        
            defaultValue={rowData != null ? rowData.nombre : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
        )
     }

     {  isDeleted ?  '' : (      
            <TextField
            required
            margin="dense"
            id="email"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}             
            label="Email Responsable"
            type="text"
            defaultValue={rowData != null ? rowData.email : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
          )
        }
   
   {  isDeleted ?  '' : (      
            <TextField
            required
            margin="dense"
            id="bd_servidor"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="BD Servidor"
            defaultValue={rowData != null ? rowData.bd_servidor : ""}
            type="text"
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
       )
   }


 
{ isDeleted ? '' : (
 
 <InputLabel  htmlFor="outlined-age-native-simple" style={martginTop}>
           Motor DB
         </InputLabel> 
 )  
 }
 { isDeleted ? '' : (
  
 <Select style ={styleClass}
 
           inputProps={{
             name: 'motordb',
             id: 'motordb'
           }}
           onChange={this.handleChangeMotor.bind(this)}  value={ this.state && this.state.motordb ? this.state.motordb : (rowData ? rowData.motordb : '')}  
         >
           <MenuItem value={"sqlserver"}>SQL SERVER</MenuItem>
           <MenuItem value={"oracle"}>ORACLE</MenuItem>
           <MenuItem value={"hanna"}>HANNA</MenuItem>
 
         </Select>
 
 )  
 }
 
 

   

   {  isDeleted ?  '' : (     
            <TextField
            required
            margin="dense"
            id="bd_nombre"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="BD Nombre"
            type="text"
            defaultValue={rowData != null ? rowData.bd_nombre : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
      )
   }     

   {  isDeleted ?  '' : (     
            <TextField
            required
            margin="dense"
            id="bd_usuario"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="BD Usuario"
            type="text"
            defaultValue={rowData != null ? rowData.bd_usuario : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}

          />
      )
   }     

   {  isDeleted ?  '' : (     
           <TextField
           required
            margin="dense"
            id="bd_clave"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="BD Clave"
            type="text"
            defaultValue={rowData != null ? rowData.bd_clave : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
      )
   }     


{  isDeleted ?  '' : (   
           <TextField
           required
            margin="dense"
            id="url_servicio"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="URL Servicio - Conexion"
            defaultValue={rowData != null ? rowData.url_servicio : ""}
            type="text"
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
       )
   }     
     





        </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>

        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>

          <Button  style={{color :"white"}} onClick={closeModal} color="primary">
            Cerrar
          </Button>

          </td>
     
     {
         isDeleted ? (
          <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
            <Button  style={{color :"#26c6da"}}   onClick={(e) => this.onDelete(e,closeModal)} color="primary">
             Aceptar
          </Button> </td>) : ''
  
     
     }

     {
       isEdited ? (
        <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
     
          <Button  style={{color :"#26c6da"}}  onClick={(e) => this.onSubmit(e,closeModal)} color="primary">
            Guardar
          </Button></td>) : ''
     }
     </tr>
     </table>

        </DialogActions>
      </Dialog>
    )
 }
}

export default DialogEmpresa;