import React from "react";
import firebase from "firebase/compat";
import MaterialTable from "material-table";
import DialogUsuario from "views/Usuario/DialogUsuario.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Switch from "react-switch";
import DialogReporte from "./DialogReporte";
import DialogReporteCupos from "./DialogReporteCupos";
import DialogSeleccionArchivo  from "./DialogSeleccionArchivo";

import DialogSeleccionListaPrecio from "./DialogSeleccionListaPrecio";
import DialogSeleccionCupo from "./DialogSeleccionCupo";




import LoadingOverlay from "react-loading-overlay";

import axios from "axios";


async function LeerDocumentos(path)
{
    console.log("PATH: "+path);
    return new Promise((resolve, reject) => {
        const db = firebase.database();    
        var ref = db.ref(path);
        ref.on("value", function(snapshot) {
            const _data = snapshot.val();
            console.log(" <--------------------> "+path+" <--------------------> ");
            resolve(_data);
          }, function (errorObject) {
            resolve([]);
            console.log("The read failed: " + errorObject.code);
          });
    });
}

class ReporteDetalle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      modalIsOpen: false,
      modalIsOpen2: false,
      modalIsOpen3: false,
      modalIsOpen4: false,
      modalIsOpen5: false,
      seleccionArchivo: "",
      isEdited: false,
      isActive: false,
      listadoReportes: [],
      parametrosReporte: props.location.state.parametrosReporte,
      rowData: {},
      rowList: [],
      checked: false,
      isDeleted: false,
      columns: [],
      actions: [],
      reporte: props.location.state.reporte,
      codigo_proveedor: props.location.state.codigo_proveedor,
      codigo_empresa: props.location.state.codigo_empresa,
      servicio: props.location.state.servicio
    };

    if (props.location.state.check == "1") {
      console.log("ACA ENTRO : " + props.location.state.check);

      this.setState({ checked: true });
    } else {
      this.setState({ checked: false });
    }

    const those = this;

    const db = firebase.firestore();
    let citiesRef = db
      .collection("APIIntegracionProveedoresDetalleReportes")
      .where("codigo_proveedor", "==", this.state.codigo_proveedor)
      .where("codigo_empresa", "==", this.state.codigo_empresa)
      .where("reporte", "==", this.state.reporte);
    let query = citiesRef.onSnapshot(
      snapshot => {
        const _data = snapshot.docs.map(doc => doc.data());
        this.setState({ listadoReportes: _data });
      },
      error => {
        console.log(error);
      }
    );

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.closeModalCupos = this.closeModalCupos.bind(this);
    this.submitModalCupos = this.submitModalCupos.bind(this);


    this.closeModalListaPrecios = this.closeModalListaPrecios.bind(this);
    this.submitModalListaPrecios = this.submitModalListaPrecios.bind(this);

    this.submitModalCuposSiesa = this.submitModalCuposSiesa.bind(this);
    this.closeModalSeleccionCupo = this.closeModalSeleccionCupo.bind(this);

    this.submitModalSeleccionArchivo = this.submitModalSeleccionArchivo.bind(this);
    this.closeModalSeleccionArchivo = this.closeModalSeleccionArchivo.bind(this);

    this.handleChange = this.handleChange.bind(this);

    const storedUsuario = localStorage.getItem("usuario");
    if (storedUsuario === null) {
      window.location.href = "/admin/dashboard";
    }
  }

  handleChange(checked) {
    let _data = this.state.listadoReportes;

    console.log("TEST: " + _data);

    if (_data.length > 0) {
      let dataFinal = _data[0];
      let _check = "0";
      if (checked) {
        _check = "1";
      } else {
        _check = "0";
      }

      dataFinal["check"] = _check;

      this.setState({ checked });
      const db = firebase.firestore();
      db.collection("APIIntegracionProveedoresDetalleReportes")
        .doc(
          dataFinal.codigo_empresa +
            "_" +
            dataFinal.codigo_proveedor +
            "_" +
            dataFinal.reporte
        )
        .set(dataFinal)
        .then(function(result) {})
        .catch(function(error) {});
    }
  }

  componentWillUnmount() {
    console.log("NO MOntaDO");
  }

  componentDidMount() {
    const those = this;
  
    let  nombre_documento =
      "tropi/reportes/" +
      this.state.codigo_proveedor +
      "/" +
      this.state.codigo_empresa +
      "/" +
      this.state.servicio;

    if (this.state.servicio == "asignacion_cupos")
    {
      nombre_documento =
      "tropi/" +
      this.state.codigo_proveedor +
      "/" +
      this.state.codigo_empresa +
      "/" +
      this.state.servicio;
    }

    const db = firebase.database();
    var ref = db.ref(nombre_documento);
    ref.on(
      "value",
      function(snapshot) {
        const _data = snapshot.val();
        var jsonObjt = Object.keys(_data[0]);

        let _tmpdata = _data;
         
        var columnsJson = [];

        if (those.state.servicio == "listaprecios") {
          _tmpdata = _data.map(dataReturn => {
            let _precio_descuento_margen = 0;
            those.state.parametrosReporte.forEach(parametros => {
              if (parametros.parametro === dataReturn.segmento_cliente) {
                _precio_descuento_margen = parametros.descuento;
              }
            });

            if (dataReturn.aplica_descuento_pagina == "1") {
              _precio_descuento_margen = parseInt(
                dataReturn.precio -
                  (dataReturn.precio * _precio_descuento_margen) / 100
              );
            } else {
              _precio_descuento_margen = parseInt(dataReturn.precio);
            }

            return {
              codigo_producto: dataReturn.codigo_producto,
              segmento_cliente: dataReturn.segmento_cliente,
              unidad_inventario: dataReturn.unidad_inventario,
              precio_descuento_margen: _precio_descuento_margen,
              precio: dataReturn.precio,
              iva: dataReturn.iva,
              impuesto_consumo: dataReturn.impuesto_consumo,
              codigo_impuesto: dataReturn.llave_impuesto
            };
          });

          columnsJson.push({
            title: "codigo_producto",
            field: "codigo_producto",
            editable: "never"
          });
          columnsJson.push({
            title: "segmento_cliente",
            field: "segmento_cliente",
            editable: "never"
          });
          columnsJson.push({
            title: "unidad_inventario",
            field: "unidad_inventario",
            editable: "never"
          });
          columnsJson.push({
            title: "precio",
            field: "precio",
            editable: "never"
          });
          columnsJson.push({
            title: "precio_descuento_margen",
            field: "precio_descuento_margen",
            editable: "never"
          });
        } else if (those.state.servicio == "cupo") {
          
          columnsJson.push({ title: "nit", field: "nit", editable: "never" });
          columnsJson.push({ title: "sucursales inactivas", field: "sucursales_inactivas", editable: "never"});
          
           columnsJson.push({ title: "sucursales efectivo", field: "sucursales_efectivo", editable: "never"});
          columnsJson.push({ title: "sucursales inhabilitadas", field: "sucursale_no_habilitadas_plan_31", editable: "never" });
          columnsJson.push({ title: "porcentaje asignacion", field: "porcentaje", editable: "never" });
          _tmpdata =[]

          const db = firebase.firestore();
          db.collection('APICuposMasBien')
          .onSnapshot(  (snapshot) => {
            const _dataddd = snapshot.docs.map(doc => doc.data());
            if(_dataddd.length > 0)
            {

 
              LeerDocumentos("tropi/"+those.state.codigo_proveedor+"/"+those.state.codigo_empresa+"/clientes_detalle").then(resultado =>
              {
 
            
                let ddtt = [];
                _dataddd[0].nits.forEach(elementddd => {
              
                  let rts = resultado.filter(docto => docto.nit == elementddd ); 
                  if(rts.length == 0)
                  {
                    ddtt.push({nit : elementddd , porcentaje : _dataddd[0].porcentaje });
                  }else
                  {
                   
                     let sucursales_inactivas = rts[0].sucursales_inactivas.split(",");
                     let sucursales_inactivas_string = "";
                     sucursales_inactivas.forEach((stringinactivas,index) => {
                        sucursales_inactivas_string = sucursales_inactivas_string +stringinactivas+" , ";
                     });
                   
                 
                     let sucursales_efectivo = rts[0].sucursales_efectivo.split(",");
                     let sucursales_efectivo_string = "";
                     sucursales_efectivo.forEach((stringefectivo,index) => {
                        sucursales_efectivo_string = sucursales_efectivo_string +stringefectivo+" , ";
                     });
                    

                     let sucursale_no_habilitadas_plan_31 = rts[0].sucursale_no_habilitadas_plan_31.split(",");
                     let ssucursale_no_habilitadas_plan_31_string = "";
                     sucursale_no_habilitadas_plan_31.forEach((stringplan31,index) => {
                        ssucursale_no_habilitadas_plan_31_string = ssucursale_no_habilitadas_plan_31_string +stringplan31+" , ";
                     });
                    

                    ddtt.push({nit : elementddd , porcentaje : _dataddd[0].porcentaje ,sucursales_inactivas : sucursales_inactivas_string , sucursales_efectivo : sucursales_efectivo_string , sucursale_no_habilitadas_plan_31 : ssucursale_no_habilitadas_plan_31_string });
              
                  }
                           
                });
  
                  those.setState({ data : ddtt});
              }).catch(errord =>{})
              
            }
          }, (error) => {
  
          });


         
        }else
        {
          Object.keys(_data[0]).forEach(element => {
            columnsJson.push({ title: element.toUpperCase(), field: element });
          });
        }

        let accionesDeLaPagina = [];

        if(those.state.servicio == "listaprecios") 
        {
          accionesDeLaPagina.push({
            icon: "send",
            tooltip: "Enviar Registros a Siesa",
            isFreeAction: true,
            onClick: async event => {
              those.setState({ modalIsOpen3 :true})

            }
          });
        }

        if (those.state.servicio == "cupo")
        {
          accionesDeLaPagina.push({
            icon: "update",
            tooltip: "Cargar Archivo",
            isFreeAction: true,
            onClick: async event => {
              those.setState({ modalIsOpen5: true });
            }
          });

          accionesDeLaPagina.push({
            icon: "send",
            tooltip: "Enviar Registros a Siesa",
            isFreeAction: true,
            onClick: async event => {

              those.setState({modalIsOpen4 :true})
              

            }
          });
        }

     
        those.setState({
          columns: columnsJson,
          data: _tmpdata,
          actions: accionesDeLaPagina  
        });
      },
      function(errorObject) {}
    );
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModalCupos() {
    this.setState({ modalIsOpen2: false });
  }

  closeModalSeleccionArchivo() {
    this.setState({ modalIsOpen5: false });
  }


  submitModalSeleccionArchivo(seleccion_archivo) {
    this.setState({seleccionArchivo : seleccion_archivo});
    this.setState({ modalIsOpen5: false });
    this.setState({ modalIsOpen2: true });
  }


  closeModalListaPrecios() {
    this.setState({ modalIsOpen3: false });
  }


  closeModalSeleccionCupo() {
    this.setState({ modalIsOpen4: false });
  }




  submitModalListaPrecios(listaPrecioSeleccionada) {
  
    this.setState({ modalIsOpen3: false });
    let those = this;

        /** INICIO  */

                let json2 = those.state.data.map(dataReturn => {
                  let tmp_lista_precio = "";
  
                  those.state.parametrosReporte.forEach(parametros => {
                    if (
                      parametros.parametro === dataReturn.segmento_cliente
                    ) {
                      tmp_lista_precio = parametros.valor;
                    }
                  });
  
                  if (
                    dataReturn.codigo_impuesto == "" ||
                    dataReturn.codigo_impuesto == null
                  ) {
                    dataReturn.codigo_impuesto = "X";
                  }
  
                  return {
                    codigo_producto: dataReturn.codigo_producto,
                    lista_precio: tmp_lista_precio,
                    embalaje: dataReturn.unidad_inventario,
                    precio: dataReturn.precio_descuento_margen,
                    codigo_impuesto: dataReturn.codigo_impuesto,
                    valor_impuesto: parseInt(dataReturn.impuesto_consumo)
                  };
                });
  
                let json3 = json2.filter(
                  precio_ => precio_.lista_precio == listaPrecioSeleccionada+""
                );
                let jsonArrayString = JSON.stringify(json3);
          
                jsonArrayString = jsonArrayString.replace(
                  /"codigo_producto"/g,
                  "codigo_producto"
                );
                jsonArrayString = jsonArrayString.replace(
                  /"lista_precio"/g,
                  "lista_precio"
                );
                jsonArrayString = jsonArrayString.replace(
                  /"embalaje"/g,
                  "embalaje"
                );
                jsonArrayString = jsonArrayString.replace(
                  /"precio"/g,
                  "precio"
                );
                jsonArrayString = jsonArrayString.replace(
                  /"codigo_impuesto"/g,
                  "codigo_impuesto"
                );
                jsonArrayString = jsonArrayString.replace(
                  /"valor_impuesto"/g,
                  "valor_impuesto"
                );
  
                let dataToSend = JSON.stringify({
                  query:
                    "mutation{ crearlistaprecioSIESA(precios: " +
                    jsonArrayString +
                    "  ){ codigo_respuesta status mensajes { f_tipo_reg f_subtipo_reg f_detalle f_valor } }}",
                  variables: {}
                });
  
                const _headers = {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "Access-Control-Allow-Methods":
                    "GET, PUT, POST, DELETE, OPTIONS",
                  "TROPI-TOKEN": "65b4b563-9c4c-4694-b03e-8652e006e2d1"
                };
  
                those.setState({ isActive: true });
  
   //                    axios.post('http://localhost:5000/gcp-tropi-gaussia/us-central1/graphql?token=4578632c-d3c8-488c-b0f9-a91ef3874929', dataToSend , {headers : _headers } )
  
               axios .post("https://us-central1-gcp-tropi-gaussia.cloudfunctions.net/graphql?token=4578632c-d3c8-488c-b0f9-a91ef3874929", dataToSend,{ headers: _headers })
                  .then(res => {
                   
  
                     console.log(JSON.stringify(res.data.data))
  
                     if (   res.data.data.crearlistaprecioSIESA.mensajes.length < 10
                    ) {
                      those.setState({
                        rowList: res.data.data.crearlistaprecioSIESA.mensajes
                      });
                    } else {
                      let dataJson = [];
                      dataJson.push(
                        res.data.data.crearlistaprecioSIESA.mensajes[0]
                      );
                      dataJson.push(
                        res.data.data.crearlistaprecioSIESA.mensajes[1]
                      );
                      dataJson.push(
                        res.data.data.crearlistaprecioSIESA.mensajes[2]
                      );
                      dataJson.push(
                        res.data.data.crearlistaprecioSIESA.mensajes[3]
                      );
                      dataJson.push(
                        res.data.data.crearlistaprecioSIESA.mensajes[4]
                      );
                      dataJson.push(
                        res.data.data.crearlistaprecioSIESA.mensajes[5]
                      );
                      dataJson.push(
                        res.data.data.crearlistaprecioSIESA.mensajes[6]
                      );
                      dataJson.push(
                        res.data.data.crearlistaprecioSIESA.mensajes[7]
                      );
                      dataJson.push(
                        res.data.data.crearlistaprecioSIESA.mensajes[8]
                      );
  
                      those.setState({ rowList: dataJson });
                    }
  
                    console.log(`statusCode: ${res.statusCode}`);
                    console.log(res);
  
                    those.setState({ isActive: false });
                    those.setState({ modalIsOpen: true });
                  })
                  .catch(error => {
                    console.log(error);
                    those.setState({ isActive: false });
                    those.setState({ modalIsOpen: true });
                  });
  }
  

  submitModalCuposSiesa(opcionSeleccionada) {  
    this.setState({ modalIsOpen4: false });  
    if(opcionSeleccionada == "0")
    {

    }else if(opcionSeleccionada == "1")
    {

      let those = this;
      those.setState({ isActive: true });
      const _headers = {  
        'Content-Type': 'application/json', 
        'Accept': 'application/json', 
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
        'TROPI-TOKEN' : '65b4b563-9c4c-4694-b03e-8652e006e2d1'
      }

      const urlData = "https://us-central1-gcp-tropi-gaussia.cloudfunctions.net/actualizacioncupoSIESA?codigo_empresa=46&codigo_proveedor=7122&accion=1";
      console.log(urlData);
  
      axios.get(urlData , {_headers : _headers})
      .then((res) => {
   
        if (   res.data.respuesta.respuesta.mensajes.length < 10
          ) {
            those.setState({
              rowList: res.data.respuesta.respuesta.mensajes
            });
          } else {
            let dataJson = [];
            dataJson.push(
             res.data.respuesta.respuesta.mensajes[0]
            );
            dataJson.push(
             res.data.respuesta.respuesta.mensajes[1]
            );
            dataJson.push(
             res.data.respuesta.respuesta.mensajes[2]
            );
            dataJson.push(
             res.data.respuesta.respuesta.mensajes[3]
            );
            dataJson.push(
             res.data.respuesta.respuesta.mensajes[4]
            );
            dataJson.push(
             res.data.respuesta.respuesta.mensajes[5]
            );
            dataJson.push(
             res.data.respuesta.respuesta.mensajes[6]
            );
            dataJson.push(
             res.data.respuesta.respuesta.mensajes[7]
            );
            dataJson.push(
             res.data.respuesta.respuesta.mensajes[8]
            );
   
            those.setState({ rowList: dataJson });
          }
   
          those.setState({ isActive: false });
          those.setState({ modalIsOpen: true });
         
      })
      .catch((error) => {
          console.log(error)
      })        

    }else if(opcionSeleccionada == "2")
    {

      let those = this;
      those.setState({ isActive: true });
      const _headers = {  
        'Content-Type': 'application/json', 
        'Accept': 'application/json', 
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
        'TROPI-TOKEN' : '65b4b563-9c4c-4694-b03e-8652e006e2d1'
      }

      const urlData = "https://us-central1-gcp-tropi-gaussia.cloudfunctions.net/actualizacioncupoSIESA?codigo_empresa=46&codigo_proveedor=7122&accion=2";
      console.log(urlData);
  
      axios.get(urlData , {_headers : _headers})
      .then((res) => {
  
        if (   res.data.respuesta.respuesta.mensajes.length < 10
       ) {
         those.setState({
           rowList: res.data.respuesta.respuesta.mensajes
         });
       } else {
         let dataJson = [];
         dataJson.push(
          res.data.respuesta.respuesta.mensajes[0]
         );
         dataJson.push(
          res.data.respuesta.respuesta.mensajes[1]
         );
         dataJson.push(
          res.data.respuesta.respuesta.mensajes[2]
         );
         dataJson.push(
          res.data.respuesta.respuesta.mensajes[3]
         );
         dataJson.push(
          res.data.respuesta.respuesta.mensajes[4]
         );
         dataJson.push(
          res.data.respuesta.respuesta.mensajes[5]
         );
         dataJson.push(
          res.data.respuesta.respuesta.mensajes[6]
         );
         dataJson.push(
          res.data.respuesta.respuesta.mensajes[7]
         );
         dataJson.push(
          res.data.respuesta.respuesta.mensajes[8]
         );

         those.setState({ rowList: dataJson });
       }

       those.setState({ isActive: false });
       those.setState({ modalIsOpen: true });
      
      })
      .catch((error) => {
          console.log(error)
      })        

    }
  }
  








  submitModalCupos() {
    this.setState({ modalIsOpen2: false });

    console.log(" INICIO 1");
    const db = firebase.firestore();
    let citiesRef = db.collection("APICuposMasBien");
    let query = citiesRef.onSnapshot(
      async snapshot => {
        console.log(" INICIO 2");
        const _data = snapshot.docs.map(doc => doc.data());

        console.log(_data);
        let consolidadoClientes = _data[0].nits;
        let valueporcentaje = _data[0].porcentaje;

        let reportdata = this.state.data;
        let lstdata = [];

        console.log("Consolidado Cientes =>" + consolidadoClientes);
        reportdata.forEach(element => {
          console.log("NIT : " + element.nit);
          if (consolidadoClientes.includes(element.nit)) {
            element.porcentaje = valueporcentaje + "";
            lstdata.push(element);
          }
        });

        console.log(lstdata.length);
        this.setState({ data: lstdata });
      },
      error => {
        console.log(error);
      }
    );
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)"
      }
    };

    let styleFloat = { marginBottom: "20px" };

    return (
      <div>
        <LoadingOverlay
          active={this.state.isActive}
          spinner
          text="Enviando  Informacion a Siesa, por favor espere un momento"
        >
          {this.state.servicio != "listaprecios" ? (
            ""
          ) : (
            <label style={styleFloat}>
              <span> Habilitar Sincronizacion Automatica </span>
              <Switch
                onChange={this.handleChange}
                checked={this.state.checked}
              />
              <h2></h2>
            </label>
          )}
          <MaterialTable
            title={this.state.reporte}
            columns={this.state.columns}
            data={this.state.data}
            actions={this.state.actions}
            options={{
              exportButton: true,
              pageSize: 15,
              exportAllData: true
            }}
          />
        </LoadingOverlay>
        <DialogReporte
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
          isEdited={this.state.isEdited}
          rowData={this.state.rowList}
          isDeleted={this.state.isDeleted}
          listadoEmpresas={this.state.listadoEmpresas}
        />

        <DialogReporteCupos
          modalIsOpen={this.state.modalIsOpen2}
          submitModalCupos={this.submitModalCupos}
          closeModalCupos={this.closeModalCupos}
          isEdited={this.state.isEdited}
          rowData={this.state.rowList}
          isDeleted={this.state.isDeleted}
          listadoEmpresas={this.state.listadoEmpresas}
          seleccionArchivo={this.state.seleccionArchivo}
        />

        <DialogSeleccionListaPrecio
          modalIsOpen={this.state.modalIsOpen3}
          submitModalListaPrecios={this.submitModalListaPrecios}
          closeModalListaPrecios={this.closeModalListaPrecios}
          isEdited={this.state.isEdited}
          rowData={this.state.rowList}
          isDeleted={this.state.isDeleted}
          listadoEmpresas={this.state.listadoEmpresas}
        />


<DialogSeleccionCupo
          modalIsOpen={this.state.modalIsOpen4}
          submitModalCuposSiesa={this.submitModalCuposSiesa}
          closeModalSeleccionCupo={this.closeModalSeleccionCupo}
          isEdited={this.state.isEdited}
          rowData={this.state.rowList}
          isDeleted={this.state.isDeleted}
          listadoEmpresas={this.state.listadoEmpresas}
        />

<DialogSeleccionArchivo
        modalIsOpen={this.state.modalIsOpen5}
        submitModalSeleccionArchivo={this.submitModalSeleccionArchivo}
        closeModalSeleccionArchivo={this.closeModalSeleccionArchivo}
        isEdited={this.state.isEdited}
        rowData={this.state.rowList}
        isDeleted={this.state.isDeleted}
        listadoEmpresas={this.state.listadoEmpresas}
      />


        





      </div>
    );
  }
}

export default ReporteDetalle;
