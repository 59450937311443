import React from "react";
import firebase from "firebase/compat";
import uuid from "uuid-random";
import MaterialTable from "material-table";
import DialogEmpresa from "views/Empresa/DialogEmpresa.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Delete from "@material-ui/icons/Delete";
import Replay from "@material-ui/icons/Replay";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import timezones from "timezones-list";

import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import "devextreme/dist/css/dx.light.css";
import Swal from "sweetalert2";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Export,
  Lookup,
  Editing,
  Item,
  Popup,
  Form,
  Selection,
  SearchPanel,
} from "devextreme-react/data-grid";
import Card from "components/Card/Card";
import { SelectBox } from "devextreme-react";

const allowedPageSizes = [5, 10, "Todos"];

const texts = {
  exportAll: "Exportar todos los datos",
  exportSelectedRows: "Exportar filas seleccionadas",
  exportTo: "Exportar",
};

const texts2 = {
  confirmDeleteMessage: "Estas seguro de eliminar la empresa?",
  cancelRowChanges: "Cancelar",
  saveRowChanges: "Guardar",
};

let dataGrid;
let dataGrid1;
let dataGrid2;

let languages = require("./data.json");

class Empresa extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      modalIsOpen: false,
      isEdited: false,
      rowData: {},
      isDeleted: false,
      erpEmpresa: "",
    };

    const db = firebase.firestore();
    db.collection("APIEmpresas").onSnapshot(
      (snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());
        let usuario = JSON.parse(localStorage.getItem("usuario"));
        let evaluar = _data.filter((e) => e.codigo === usuario.codigo_empresa);

        if (evaluar.length === 0) {
          this.setState({ data: _data });
        } else {
          this.setState({ data: evaluar });
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    const storedUsuario = localStorage.getItem("usuario");
    if (storedUsuario === null) {
      window.location.href = "/admin/dashboard";
    }

    this.onInitialized = this.onInitialized.bind(this);
    this.onInitialized1 = this.onInitialized1.bind(this);
    this.onInitialized2 = this.onInitialized2.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  guardarData = (row) => {
    console.log(row.data);
    const db = firebase.firestore();
    db.collection("APIEmpresas")
      .doc(row.data.codigo)
      .set(row.data)
      .then(function(result) {})
      .catch(function(error) {});
  };

  borrarData = (row) => {
    try {
      const db = firebase.firestore();
      db.collection("APIEmpresas")
        .doc(row.data.codigo)
        .delete()
        .then(function(result) {})
        .catch(function(error) {});
    } catch (err) {}
  };

  onInitialized(e) {
    dataGrid = e.component;
  }

  onInitialized1(e) {
    dataGrid1 = e.component;
  }

  onInitialized2(e) {
    dataGrid2 = e.component;
  }

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    let motorDBOptions = ["SQL Server", "Oracle"];

    let positionEditorOptions = {
      items: motorDBOptions,
      searchEnabled: true,
      value: "",
    };

    let sql = [
      "sqlserver2005",
      "sqlserver2008",
      "sqlserver2012",
      "sqlserver2014",
      "sqlserver2016",
      "sqlserver2017",
      "sqlserver2019",
      "sqlserver2022",
      "Hana",
    ];

    let positionEditorOptions1 = {
      items: sql,
      searchEnabled: true,
      value: "",
    };

    let idiomas = languages.map((e) => e.language);

    let positionEditorOptions2 = {
      items: idiomas,
      searchEnabled: true,
      value: "",
    };

    let zonaHoraria = timezones.map((e) => e.label);

    let positionEditorOptions3 = {
      items: zonaHoraria,
      searchEnabled: true,
      value: "",
    };

    let trusted = ["Si", "No"];

    let positionEditorOptions4 = {
      items: trusted,
      searchEnabled: true,
      value: "",
    };

    let ancho = window.screen.width;

    const renderGridCell = (row) => (
      <div>
        <Tooltip title="Eliminar">
          <IconButton>
            <Delete
              onClick={() => {
                dataGrid.deleteRow(row.rowIndex);
              }}
              style={{
                cursor: "pointer",
                color: "#337ab7",
              }}
            />
          </IconButton>
        </Tooltip>{" "}
        <Tooltip title="Editar">
          <IconButton>
            <Edit
              onClick={() => {
                if (row.data.erp === "SAP BUSINESS ONE") {
                  this.setState({ erpEmpresa: row.data.erp });
                  dataGrid1.editRow(row.rowIndex);
                }
                if (row.data.erp === "SAP R3") {
                  dataGrid2.editRow(row.rowIndex);
                  this.setState({ erpEmpresa: row.data.erp });
                }
                if (row.data.erp === "SIESA") {
                  this.setState({ erpEmpresa: row.data.erp });
                  dataGrid.editRow(row.rowIndex);
                }
                if (row.data.erp === undefined) {
                  dataGrid.editRow(row.rowIndex);
                }
              }}
              style={{
                cursor: "pointer",
                color: "#337ab7",
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Generar Token">
          <IconButton>
            <Replay
              onClick={() => {
                Swal.fire({
                  title: "Deseas generar token?",
                  showCancelButton: true,
                  cancelButtonText: "Cancelar",
                  confirmButtonText: "Crear",
                  confirmButtonColor: "#2daae1",
                }).then((result) => {
                  if (result.isConfirmed) {
                    let newData = row.data;
                    new Promise((resolve) => {
                      resolve();
                      const secret_key_ = uuid();
                      const token_ = uuid();
                      let access_key_ = uuid(); //jwt.encode(payload, secret);

                      const db = firebase.firestore();
                      db.collection("APIEmpresas")
                        .doc(newData.codigo)
                        .set({
                          ...newData,
                          access_key: access_key_,
                          secret_key: secret_key_,
                          token: token_,
                        })
                        .then((result) => {})
                        .catch(function(error) {});
                    });
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Token creado",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                });
              }}
              style={{
                cursor: "pointer",
                color: "#337ab7",
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    );

    return (
      <div>
        <Grid
          style={{
            position: "relative",
          }}
        >
          <Grid
            style={{
              position: "absolute",
            }}
          >
            <Card
              style={{
                padding: 10,
                width: "60%",
              }}
            >
              <Box width="60%">
                <DataGrid
                  dataSource={this.state.data}
                  keyExpr="codigo"
                  showColumnLines={true}
                  // onRowClick={e => console.log(e)}
                  onExporting={(e) => {}}
                  showHeaderFilter={true}
                  showRowLines={true}
                  onInitNewRow={(e) => {
                    e.data = { erp: "SAP R3" };
                    console.log("initrow", e.data);
                  }}
                  columnAutoWidth={true}
                  showBorders={true}
                  onCellPrepared={(e) => {
                    e.cellElement.classList.add("adaptiveRowStyle");
                  }}
                  onRowRemoved={(row) => {
                    this.borrarData(row);
                  }}
                  onRowInserted={(row) => {
                    this.guardarData(row);
                  }}
                  onInitialized={this.onInitialized2}
                  onRowUpdated={(row) => {
                    this.guardarData(row);
                  }}
                  rowAlternationEnabled={true}
                >
                  <Editing
                    mode="popup"
                    allowUpdating={false}
                    allowAdding={true}
                    allowDeleting={false}
                    useIcons={true}
                    texts={texts2}
                  >
                    <Popup
                      title="LISTADO DE EMPRESAS"
                      showTitle={true}
                      width={ancho > 800 ? "900px" : ""}
                      height={ancho > 800 ? "440px" : ""}
                    />
                    <Form>
                      <Item itemType="group" colCount={2} colSpan={2}>
                        <Item
                          dataField="codigo"
                          caption="Código"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="erp"
                          caption="ERP"
                          disabled={true}
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          caption="ID Sistema"
                          dataField="id_sistema"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          caption="Nombre"
                          dataField="nombre"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          caption="Clave"
                          dataField="clave"
                          editorOptions={{ mode: "password" }}
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="email"
                          caption="Email Responsable"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="numero_instancia"
                          caption="Número de instancia"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="string_saprouter"
                          caption="String de SAProuter"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="servidor_aplicacion"
                          caption="Servidor de aplicación"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="mandante"
                          caption="Mandante"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="TREINTA_token"
                          caption="X-API-KEY"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="TREINTA_shop"
                          caption="TREINTA Supplier ID"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        
                        <Item
                          dataField="TREINTA_shop2"
                          caption="TREINTA Supplier Secret"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />

                      </Item>
                    </Form>
                  </Editing>
                  <HeaderFilter visible={true} />
                  <Column dataField="codigo" caption="Código" visible={false} />
                  <Column dataField="erp" caption="ERP" value="SAP R3" />
                  <Column
                    caption="ID Sistema"
                    dataField="id_sistema"
                    visible={false}
                  />
                  <Column caption="Nombre" dataField="nombre" visible={false} />
                  <Column caption="Clave" dataField="clave" visible={false} />
                  <Column
                    dataField="email"
                    caption="Email Responsable"
                    visible={false}
                  />
                  <Column
                    dataField="numero_instancia"
                    caption="Número de instancia"
                    visible={false}
                  />
                  <Column
                    dataField="string_saprouter"
                    caption="String de SAProuter"
                    visible={false}
                  />
                  <Column
                    dataField="servidor_aplicacion"
                    caption="Servidor de aplicación"
                    visible={false}
                  />
                  <Column
                    dataField="mandante"
                    caption="Mandante"
                    visible={false}
                  />
                  <Column
                    dataField="TREINTA_token"
                    caption="X-API-KEY"
                    visible={false}
                  />
                  <Column
                    dataField="TREINTA_shop"
                    caption="TREINTA Supplier ID"
                    visible={false}
                  />
                   <Column
                    dataField="TREINTA_shop2"
                    caption="TREINTA Supplier Secret"
                    visible={false}
                  />
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode="full"
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={true}
                    texts={texts}
                  />
                </DataGrid>
              </Box>
            </Card>
          </Grid>
        </Grid>

        <Grid
          style={{
            position: "relative",
          }}
        >
          <Grid
            style={{
              position: "absolute",
            }}
          >
            <Card
              style={{
                padding: 10,
                width: "60%",
              }}
            >
              <Box width="60%">
                <DataGrid
                  dataSource={this.state.data}
                  keyExpr="codigo"
                  showColumnLines={true}
                  // onRowClick={e => console.log(e)}
                  onExporting={(e) => {}}
                  showHeaderFilter={true}
                  showRowLines={true}
                  onInitNewRow={(e) => {
                    e.data = { erp: "SAP BUSINESS ONE" };
                    console.log("initrow", e.data);
                  }}
                  columnAutoWidth={true}
                  showBorders={true}
                  onCellPrepared={(e) => {
                    e.cellElement.classList.add("adaptiveRowStyle");
                  }}
                  onRowRemoved={(row) => {
                    this.borrarData(row);
                  }}
                  onRowInserted={(row) => {
                    this.guardarData(row);
                  }}
                  onInitialized={this.onInitialized1}
                  onRowUpdated={(row) => {
                    this.guardarData(row);
                  }}
                  rowAlternationEnabled={true}
                >
                  <Editing
                    mode="popup"
                    allowUpdating={false}
                    allowAdding={true}
                    allowDeleting={false}
                    useIcons={true}
                    texts={texts2}
                  >
                    <Popup
                      title="LISTADO DE EMPRESAS"
                      showTitle={true}
                      height={620}
                    />
                    <Form>
                      <Item itemType="group" colCount={2} colSpan={2}>
                        <Item
                          dataField="codigo"
                          caption="Código"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="erp"
                          caption="ERP"
                          disabled={true}
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="nombre"
                          caption="Nombre"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          caption="Nombre de base de datos de la sociedad"
                          dataField="base_datos"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="email"
                          caption="Email Responsable"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="bd_usuario"
                          caption="Usuarios de base de datos"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="bd_clave"
                          caption="Clave de acceso a base de datos"
                          editorOptions={{ mode: "password" }}
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="bd_tipo"
                          caption="Tipo de base de datos"
                          editorType="dxSelectBox"
                          editorOptions={positionEditorOptions1}
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="lenguaje"
                          caption="SAP Language"
                          editorType="dxSelectBox"
                          editorOptions={positionEditorOptions2}
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="bd_servidor"
                          caption="Servidor de base de datos (host)"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="servidor_sap"
                          caption="Servidor SAP (DI) - opcional"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="id_usuario_sap"
                          caption="ID de usuario de sap (Usuario manager)"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="password_sap"
                          caption="Password de SAP"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="servidor_licencia_sap"
                          caption="Servidor de licencias SAP (host)"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="sap_sld_server"
                          caption="SAP SLD Server"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="trusted"
                          caption="Use Trusted"
                          editorType="dxSelectBox"
                          editorOptions={positionEditorOptions4}
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="zona_horaria"
                          caption="Zona horaria"
                          editorType="dxSelectBox"
                          editorOptions={positionEditorOptions3}
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="TREINTA_token"
                          caption="X-API-KEY"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                        <Item
                          dataField="TREINTA_shop"
                          caption="TREINTA Supplier ID"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />

<Item
                          dataField="TREINTA_shop2"
                          caption="TREINTA Supplier Secret"
                          label={{ location: ancho < 800 ? "top" : "left" }}
                        />
                      </Item>
                    </Form>
                  </Editing>
                  <HeaderFilter visible={true} />
                  <Column dataField="codigo" caption="Código" visible={false} />
                  <Column
                    dataField="erp"
                    caption="erp"
                    value="SAP BUSINESS ONE"
                  />
                  <Column dataField="nombre" caption="Nombre" />
                  <Column
                    caption="Nombre de base de datos de la sociedad"
                    dataField="base_datos"
                    visible={false}
                  />
                  <Column
                    dataField="email"
                    caption="Email Responsable"
                    visible={false}
                  />
                  <Column
                    dataField="bd_usuario"
                    caption="Usuarios de base de datos"
                    visible={false}
                  />
                  <Column
                    dataField="bd_clave"
                    caption="Clave de acceso a base de datos"
                    visible={false}
                  />
                  <Column
                    dataField="bd_tipo"
                    caption="Tipo de base de datos"
                    visible={false}
                  />
                  <Column
                    dataField="lenguaje"
                    caption="SAP Language"
                    visible={false}
                  />
                  <Column
                    dataField="bd_servidor"
                    caption="Servidor de base de datos (host)"
                    visible={false}
                  />
                  <Column
                    dataField="servidor_sap"
                    caption="Servidor SAP (DI) - opcional"
                    visible={false}
                  />
                  <Column
                    dataField="id_usuario_sap"
                    caption="ID de usuario de sap (Usuario manager)"
                    visible={false}
                  />
                  <Column
                    dataField="password_sap"
                    caption="Password de SAP"
                    visible={false}
                  />
                  <Column
                    dataField="servidor_licencia_sap"
                    caption="Servidor de licencias SAP (host)"
                    visible={false}
                  />
                  <Column
                    dataField="sap_sld_server"
                    caption="SAP SLD Server"
                    visible={false}
                  />
                  <Column
                    dataField="trusted"
                    caption="Use Trusted"
                    visible={false}
                  />
                  <Column
                    dataField="zona_horaria"
                    caption="Zona horaria"
                    visible={false}
                  />
                  <Column
                    dataField="TREINTA_token"
                    caption="X-API-KEY"
                    visible={false}
                  />
                 <Column
                    dataField="TREINTA_shop"
                    caption="TREINTA Supplier ID"
                    visible={false}
                  />
                  
                 <Column
                    dataField="TREINTA_shop2"
                    caption="TREINTA Supplier Secret"
                    visible={false}
                  />
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode="full"
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={true}
                    texts={texts}
                  />
                </DataGrid>
              </Box>
            </Card>
          </Grid>
        </Grid>

        <Grid>
          <Card
            style={{
              padding: 10,
              width: "100%",
            }}
          >
            <Box width="100%">
              <h4
                style={{
                  fontWeight: 500,
                  position: "relative",
                }}
              >
                LISTADO DE EMPRESAS
              </h4>
              <div
                style={{
                  position: "absolute",
                  zIndex: 100,
                  right: 70,
                }}
              >
                <button
                  onClick={() =>
                    (async () => {
                      await Swal.fire({
                        title: "Crear Empresa",
                        input: "select",
                        cancelButtonText: "Cancelar",
                        confirmButtonText: "Crear",
                        confirmButtonColor: "#2daae1",
                        inputOptions: {
                          SIESA: "SIESA",
                          SAP_BUSINESS_ONE: "SAP BUSINESS ONE",
                          SAP_R3: "SAP R3",
                        },
                        inputPlaceholder: "Selecciona ERP",
                        showCancelButton: true,
                        inputValidator: (value) => {
                          return new Promise((resolve) => {
                            if (value === "SIESA") {
                              resolve(dataGrid.addRow());
                            } else if (value === "SAP_BUSINESS_ONE") {
                              resolve(dataGrid1.addRow());
                            } else if (value === "SAP_R3") {
                              resolve(dataGrid2.addRow());
                            } else {
                              resolve("Debes seleccionar una ERP");
                            }
                          });
                        },
                      });
                    })()
                  }
                  style={{
                    border: "none",
                    background: "transparent",
                    border: "1px solid #00000030",
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 36,
                    cursor: "pointer",
                  }}
                >
                  <Add />
                </button>
              </div>
              <DataGrid
                dataSource={this.state.data}
                keyExpr="codigo"
                showColumnLines={true}
                // onRowClick={e => console.log(e)}
                onInitNewRow={(e) => {
                  e.data = { erp: "SIESA" };
                  console.log("initrow", e.data);
                }}
                onExporting={(e) => {}}
                showHeaderFilter={true}
                showRowLines={true}
                columnAutoWidth={true}
                showBorders={true}
                onCellPrepared={(e) => {
                  e.cellElement.classList.add("adaptiveRowStyle");
                }}
                onRowRemoved={(row) => {
                  this.borrarData(row);
                }}
                onRowInserted={(row) => {
                  this.guardarData(row);
                }}
                onInitialized={this.onInitialized}
                onRowUpdated={(row) => {
                  this.guardarData(row);
                }}
                rowAlternationEnabled={true}
              >
                <Editing
                  mode="popup"
                  allowUpdating={false}
                  allowAdding={false}
                  allowDeleting={false}
                  useIcons={true}
                  texts={texts2}
                >
                  <Popup
                    title="LISTADO DE EMPRESAS"
                    showTitle={true}
                    width={ancho > 800 ? "900px" : ""}
                    height={ancho > 800 ? "440px" : ""}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item
                        dataField="erp"
                        caption="erp"
                        disabled={true}
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="codigo"
                        caption="Código"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="nombre"
                        caption="Nombre"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="email"
                        caption="Email Responsable"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="bd_servidor"
                        caption="BD Servidor"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="motordb"
                        caption="Motor DB"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions}
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="bd_nombre"
                        caption="BD Nombre"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="bd_usuario"
                        caption="BD Usuario"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="bd_clave"
                        caption="BD Clave"
                        editorOptions={{ mode: "password" }}
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="url_servicio"
                        caption="URL Servicio - Conexión"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="TREINTA_token"
                        caption="X-API-KEY"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="TREINTA_shop"
                        caption="TREINTA Supplier ID"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />

                     <Item
                        dataField="TREINTA_shop2"
                        caption="TREINTA Supplier Secret"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />

                    </Item>
                  </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <Column
                  caption="Acciones"
                  cellRender={renderGridCell}
                  width={160}
                />
                <Column
                  dataField="erp"
                  caption="erp"
                  value="SIESA"
                  visible={false}
                />
                <Column dataField="codigo" caption="Código" />
                <Column dataField="nombre" caption="Nombre" />
                <Column dataField="email" caption="Email Responsable" />
                <Column
                  dataField="bd_servidor"
                  caption="BD Servidor"
                  visible={false}
                />
                <Column
                  dataField="motordb"
                  caption="Motor DB"
                  visible={false}
                />
                <Column
                  dataField="bd_nombre"
                  caption="BD Nombre"
                  visible={false}
                />
                <Column
                  dataField="bd_usuario"
                  caption="BD Usuario"
                  visible={false}
                />
                <Column
                  dataField="bd_clave"
                  caption="BD Clave"
                  visible={false}
                />
                <Column
                  dataField="url_servicio"
                  caption="URL Servicio - Conexión"
                  visible={false}
                />
                <Column dataField="access_key" caption="YUBA Token" />
                <Column dataField="token" caption="YUBA Key" />
                <Column dataField="TREINTA_token" caption="X-API-KEY" />
                <Column dataField="TREINTA_shop" caption="TREINTA Supplier ID" />
                <Column dataField="TREINTA_shop2" caption="TREINTA Supplier Secret" />
                
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode="full"
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  texts={texts}
                />
              </DataGrid>
            </Box>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default Empresa;
