import  React from "react";
import firestore from "firestore";
import firebase from "firebase/compat";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
 
class DialogProveedor extends React.Component {

constructor (props) {
        super (props)       
}

componentWillReceiveProps = (nextProps) => {
    var  _fecha_creacion = firebase.firestore.FieldValue.serverTimestamp()

    if(nextProps.rowData != null)
    {
        this.setState({   
            estado :true,
            fecha_creacion: _fecha_creacion,
            fecha_actualizacion : _fecha_creacion,
            codigo: nextProps.rowData.codigo ,
            email: nextProps.rowData.email,
            nombre: nextProps.rowData.nombre,
            responsable: nextProps.rowData.responsable
        });
    }else
    {
        this.setState({   
            estado :true,
            fecha_creacion: _fecha_creacion,
            fecha_actualizacion : _fecha_creacion,
            responsable: "",
            codigo: "",
            email: "",
            nombre: ""
        });
    }
}


 handleChange(e){
        this.setState({
            [e.target.id]: e.target.value
        })
    }

 
 onDelete = (e,closeModal) => {
        e.preventDefault();
        const db = firebase.firestore();    
        
         db.collection('APIProveedores').doc(this.state.codigo).delete().then(function(result)
         {
             closeModal();
         }).catch(function(error)
         {
              closeModal();
         });
  }

  onSubmit = (e,closeModal) => {
       e.preventDefault();
       console.log(this.state);
       const db = firebase.firestore();          
        db.collection('APIProveedores').doc(this.state.codigo).set(this.state).then(function(result){
            closeModal();
        }).catch(function(error)
        {
            closeModal();
        });  
}

render()
{
const {modalIsOpen, closeModal,isEdited,rowData,isDeleted} = this.props;

console.log("DELETED: "+isDeleted);

    return(
        <Dialog open={modalIsOpen} onClose={closeModal} aria-labelledby="form-dialog-title">

        { isDeleted ?  '' : (
        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Detalle de Proveedor</DialogTitle> )  
        }

        <DialogContent dividers>

        {  isDeleted ?  (   <DialogContentText> 
         <strong>    Esta seguro que desea eliminar el registro del Proveedor: {rowData.nombre} </strong>
          </DialogContentText> ) : ( <DialogContentText> 
         <strong>    Favor diligencia correctamente los datos del proveedor. </strong>
        </DialogContentText>
          )
        }

{  isDeleted ?  '' : (  

  
  rowData != null && isEdited ? (
         <TextField
            autoFocus
            disabled 
            margin="dense"
            id="codigo"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="Codigo"
            type="text"
            defaultValue={rowData != null ? rowData.codigo : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
        )   :
        (
          <TextField
          autoFocus
          margin="dense"
          id="codigo"
          onKeyUp={this.handleChange.bind(this)}         
          onChange={this.handleChange.bind(this)}        
          label="Codigo"
          type="text"
          defaultValue={rowData != null ? rowData.codigo : ""}
          fullWidth
          InputProps={{
              readOnly: isEdited ? false : true,
            }}
        />

        )


      )
   }

{  isDeleted ?  '' : (  
         <TextField
            required
            margin="dense"
            id="nombre"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="Nombre"
            type="text"
        
            defaultValue={rowData != null ? rowData.nombre : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
        )
     }
   
   {  isDeleted ?  '' : (      
            <TextField
            required
            margin="dense"
            id="responsable"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="Responsable"
            defaultValue={rowData != null ? rowData.responsable : ""}
            type="text"
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
       )
   }

   {  isDeleted ?  '' : (     
            <TextField
            required
            margin="dense"
            id="email"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="Email"
            type="text"
            defaultValue={rowData != null ? rowData.email : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
      )
   }  

        </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>

        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>

     
      
          <Button style={{color :"white"}}  onClick={closeModal} color="primary">
            Cerrar
          </Button>

          </td>

     
     {
         isDeleted ? (
          <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
            <Button   style={{color :"#26c6da"}}   onClick={(e) => this.onDelete(e,closeModal)} color="primary">
             Aceptar
          </Button></td>) : ''
     }

     {
       isEdited ? (
        <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
          <Button  style={{color :"#26c6da"}}    onClick={(e) => this.onSubmit(e,closeModal)} color="primary">
            Guardar
          </Button></td>) : ''
     }

     </tr>
     </table>
        </DialogActions>
      </Dialog>
    )
 }
}

export default DialogProveedor;