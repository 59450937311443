import React from "react";
import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import "devextreme/dist/css/dx.light.css";
import Swal from "sweetalert2";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Export,
  Lookup,
  Editing,
  Item,
  Popup,
  Form,
  Selection,
  SearchPanel,
} from "devextreme-react/data-grid";
import Card from "components/Card/Card";
import { SelectBox } from "devextreme-react";

const allowedPageSizes = [5, 10, "Todos"];

const texts = {
  exportAll: "Exportar todos los datos",
  exportSelectedRows: "Exportar filas seleccionadas",
  exportTo: "Exportar",
};

const texts2 = {
  confirmDeleteMessage: "Estas seguro de eliminar la empresa?",
  cancelRowChanges: "Cancelar",
  saveRowChanges: "Guardar",
};

class Reporte extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        {
          _id: "631a2b3c5b7a7e1b8b11308e",
          fecha_long: "637982633879016653",
          fecha_date: "2022-09-08T17:49:47.901+00:00",
          device_id: "00252-10000-00000-AA228",
          yuba_token: "00aede01-4452-4628-9fa8-046b0c610b2a",
          yuba_key: "634d9d79-4aab-4c12-8c3b-394feac2c779",
          servicio: "productos",
        },
        {
          _id: "631a2b3c5b7a7e1b8b11308e",
          fecha_long: "637982633879016653",
          fecha_date: "2022-09-08T17:49:47.901+00:00",
          device_id: "00252-10000-00000-AA228",
          yuba_token: "00aede01-4452-4628-9fa8-046b0c610b2a",
          yuba_key: "634d9d79-4aab-4c12-8c3b-394feac2c779",
          servicio: "productos",
        },
        {
          _id: "631a2b3c5b7a7e1b8b11308e",
          fecha_long: "637982633879016653",
          fecha_date: "2022-09-08T17:49:47.901+00:00",
          device_id: "00252-10000-00000-AA228",
          yuba_token: "00aede01-4452-4628-9fa8-046b0c610b2a",
          yuba_key: "634d9d79-4aab-4c12-8c3b-394feac2c779",
          servicio: "productos",
        },
        {
          _id: "631a2b3c5b7a7e1b8b11308e",
          fecha_long: "637982633879016653",
          fecha_date: "2022-09-08T17:49:47.901+00:00",
          device_id: "00252-10000-00000-AA228",
          yuba_token: "00aede01-4452-4628-9fa8-046b0c610b2a",
          yuba_key: "634d9d79-4aab-4c12-8c3b-394feac2c779",
          servicio: "productos",
        },
        {
          _id: "631a2b3c5b7a7e1b8b11308e",
          fecha_long: "637982633879016653",
          fecha_date: "2022-09-08T17:49:47.901+00:00",
          device_id: "00252-10000-00000-AA228",
          yuba_token: "00aede01-4452-4628-9fa8-046b0c610b2a",
          yuba_key: "634d9d79-4aab-4c12-8c3b-394feac2c779",
          servicio: "productos",
        },
        {
          _id: "631a2b3c5b7a7e1b8b11308e",
          fecha_long: "637982633879016653",
          fecha_date: "2022-09-08T17:49:47.901+00:00",
          device_id: "00252-10000-00000-AA228",
          yuba_token: "00aede01-4452-4628-9fa8-046b0c610b2a",
          yuba_key: "634d9d79-4aab-4c12-8c3b-394feac2c779",
          servicio: "productos",
        },
      ],
    };
  }

  render() {
    return (
      <div>
        <Grid>
          <Card
            style={{
              padding: 10,
              width: "100%",
            }}
          >
            <Box width="100%">
              <h4
                style={{
                  fontWeight: 500,
                  position: "relative",
                }}
              >
                MONITOR SERVICIO
              </h4>
              <div
                style={{
                  position: "absolute",
                  zIndex: 100,
                  right: 70,
                }}
              ></div>
              <DataGrid
                dataSource={this.state.data}
                keyExpr="_id"
                showColumnLines={true}
                onRowClick={() => {}}
                onInitNewRow={() => {}}
                onExporting={(e) => {}}
                showHeaderFilter={true}
                showRowLines={true}
                columnAutoWidth={true}
                showBorders={true}
                onCellPrepared={() => {}}
                onRowRemoved={() => {}}
                onRowInserted={() => {}}
                onInitialized={() => {}}
                onRowUpdated={() => {}}
                rowAlternationEnabled={true}
              >
                <Editing
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  useIcons={true}
                  texts={texts2}
                >
                  <Popup
                    title="Monitor Servicio"
                    showTitle={true}
                    width={700}
                    height={400}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item dataField="nombre_empresa" caption="Empresa" />
                      <Item dataField="CardName" caption="Proveedor" />
                      <Item dataField="fecha_date" caption="Fecha" />
                      <Item dataField="fecha_long" caption="Fecha" />
                      <Item dataField="device_id" caption="Código" />
                      <Item dataField="yuba_token" caption="Yuba Token" />
                      <Item dataField="yuba_key" caption="Yuba Key" />
                      <Item dataField="servicio" caption="Servicios" />
                    </Item>
                  </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <Column caption="Acciones" width={160} />
                <Column dataField="nombre_empresa" caption="Empresa" />
                <Column dataField="CardName" caption="Proveedor" />
                <Column dataField="fecha_date" caption="Fecha" />
                <Column dataField="fecha_long" caption="Fecha" />
                <Column dataField="device_id" caption="Código" />
                <Column dataField="yuba_token" caption="Yuba Token" />
                <Column dataField="yuba_key" caption="Yuba Key" />
                <Column dataField="servicio" caption="Servicios" />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode="full"
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  texts={texts}
                />
              </DataGrid>
            </Box>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default Reporte;
