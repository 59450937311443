import  React from "react";
import firestore from "firestore";
import firebase from "firebase/compat";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import sqlFormatter from "sql-formatter";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class DialogServicioQuery extends React.Component {

constructor (props) {
        super (props)       
}

componentWillReceiveProps = (nextProps) => {
    var  _fecha_creacion = firebase.firestore.FieldValue.serverTimestamp()

    if(nextProps.rowData != null)
    {
        this.setState({   
          nombre: nextProps.rowData.nombre ,
          query: nextProps.rowData.query,
          tiempo: nextProps.rowData.tiempo 
        });
    }else
    {
        this.setState({   
          nombre: "",
          query: "",
          tiempo: ""
        });
    }
}


 handleChange(e){
        this.setState({
            [e.target.id]: e.target.value
        })
    }

  onSubmit = (e,closeModal) => {
       e.preventDefault();

       const db = firebase.firestore();    
       db.collection('APIServicios').doc(this.state.nombre).set(
         { 
            nombre:this.state.nombre,
            query:this.state.query,
            tiempo:this.state.tiempo      
         }
         ).then(function(result){
 
       }).catch(function(error)
       {

       });  

       console.log(this.state);

      closeModal();
}

render()
{
  const {modalIsOpen, closeModal,rowData} = this.props;

  const rows = rowData;
  let _showsql = "";

  console.log("<------------------------->");
  console.log(rowData.query );
  console.log("<------------------------->");

  if (typeof rowData.query === 'undefined'){
      console.log(" null value");
  }else 
  {
    _showsql  = sqlFormatter.format(rowData.query);
  }
 

  const styledata = {"font-size": "12px"};

    return(
        <Dialog open={modalIsOpen} onClose={closeModal} aria-labelledby="form-dialog-title">

        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Edicion Query </DialogTitle> 
     
        <DialogContent dividers>

        
        <textarea rows="30" cols="80" id="query"     onKeyUp={this.handleChange.bind(this)}      style={styledata}    
        onChange={this.handleChange.bind(this)}     >
                    {_showsql}
        </textarea>

        </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>
        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>

     
 
          <Button   style={{color :"white"}}  onClick={(e) => this.onSubmit(e,closeModal)} color="primary">
            Guardar
          </Button>
          </td>
         
          <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
          <Button  style={{color :"#26c6da"}}   onClick={closeModal} color="primary">
            Cerrar
          </Button>
          </td>
          </tr>
          </table>

        </DialogActions>
      </Dialog>
    )
 }
}

export default DialogServicioQuery;