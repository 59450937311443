import React from "react";
import firebase from "firebase/compat";
import MaterialTable from "material-table";
import DialogUsuario from "views/Usuario/DialogUsuario.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import Build from "@material-ui/icons/Build";
import Delete from "@material-ui/icons/Delete";
import CloudUpload from "@material-ui/icons/CloudUpload";
import AssignmentRounded from "@material-ui/icons/AssignmentRounded";

import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import "devextreme/dist/css/dx.light.css";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Export,
  Lookup,
  Editing,
  Item,
  Popup,
  Form,
  Selection,
  SearchPanel,
} from "devextreme-react/data-grid";
import Card from "components/Card/Card";

const allowedPageSizes = [5, 10, "Todos"];

const texts = {
  exportAll: "Exportar todos los datos",
  exportSelectedRows: "Exportar filas seleccionadas",
  exportTo: "Export",
};

const texts2 = {
  confirmDeleteMessage: "Estas seguro de eliminar el producto?",
  cancelRowChanges: "Cancelar",
  saveRowChanges:"Guardar"
};

class ConfiguracionAvanzadaServicio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      modalIsOpen: false,
      isEdited: false,
      rowData: {},
      isDeleted: false,
      codigo_empresa: props.location.state.codigo_empresa,
      nombre_empresa: props.location.state.nombre_empresa,
      codigo_proveedor: props.location.state.codigo_proveedor,
      nombre_proveedor: props.location.state.nombre_proveedor,
      servicio: props.location.state.servicio,
    };

    const db = firebase.firestore();
    let citiesRef = db
      .collection("APIIntegracionProveedoresDetalleConfiguracionServicio")
      .where("codigo_proveedor", "==", props.location.state.codigo_proveedor)
      .where("codigo_empresa", "==", props.location.state.codigo_empresa)
      .where("servicio", "==", props.location.state.servicio);
    citiesRef.onSnapshot(
      (snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());

        this.setState({
          columns: [
            {
              title: "Parametro",
              field: "parametro",
              editable: "onAdd",
              lookup: {
                TIPO_PEDIDO: "Tipo Pedido",
                CENTRO_OPERACION: "Centro Operacion",
                LISTA_PRECIO: "Lista Precio",
                CODIGO_BODEGA: "Codigo Bodega",
                CODIGO_TERMINOPAGO: "Codigo Termino Pago",
                CODIGO_CLIENTE: "Codigo Cliente Ocasional",
                CODIGO_SUCURSAL: "Codigo Sucursal Ocasional",
                CODIGO_TERCERO_VENDEDOR: "Codigo Tercero Vendedor",
              },
            },
            {
              title: "Valor",
              field: "valor",
              lookup: { String: "Texto", Int: "Numerico", Float: "Decimal" },
            },
            { title: "Valor Default", field: "default" },
          ],
          data: _data,
        });
      },
      (error) => {
        console.log(error);
      }
    );

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    const storedUsuario = localStorage.getItem("usuario");
    if (storedUsuario === null) {
      window.location.href = "/admin/dashboard";
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    var tituloNombre =
      "" +
      this.state.servicio +
      " - " +
      this.state.nombre_proveedor +
      " - " +
      this.state.nombre_empresa;

    let tipoPedido = [
      "Tipo Pedido",
      "Centro Operacion",
      "Lista Precio",
      "Codigo Bodega",
      "Codigo Termino Pago",
      "Codigo Cliente Ocasional",
      "Codigo Sucursal Ocasional",
      "Codigo Tercero Vendedor",
    ];

    let positionEditorOptions1 = {
      items: tipoPedido,
      searchEnabled: true,
      value: "",
    };

    let valor = ["Texto", "Numerico", "Decimal"];

    let positionEditorOptions2 = {
      items: valor,
      searchEnabled: true,
      value: "",
    };

    console.log("data", this.state.data);

    return (
      <div>
        {/* <MaterialTable
          title={tituloNombre}
          columns={this.state.columns}
          data={this.state.data}
          actions={this.state.actions}
          options={{
            exportButton: true,
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  console.log("new sdata", newData)
                  {
                    const data = this.state.data;
                    const db = firebase.firestore();

                    db.collection(
                      "APIIntegracionProveedoresDetalleConfiguracionServicio"
                    )
                      .doc(
                        this.state.codigo_empresa +
                          "_" +
                          this.state.codigo_proveedor +
                          "_" +
                          this.state.servicio +
                          "_" +
                          newData.parametro
                      )
                      .set({
                        codigo_empresa: this.state.codigo_empresa,
                        codigo_proveedor: this.state.codigo_proveedor,
                        nombre_empresa: this.state.nombre_empresa,
                        nombre_proveedor: this.state.nombre_proveedor,
                        servicio: this.state.servicio,
                        parametro: newData.parametro,
                        valor: newData.valor,
                        default: newData.default == null ? "" : newData.default,
                        descuento:
                          newData.descuento == null ? "" : newData.descuento,
                        portafolio:
                          newData.portafolio == null ? "" : newData.portafolio,
                      })
                      .then(function(result) {})
                      .catch(function(error) {});

                    this.setState({ data }, () => resolve());
                  }
                  resolve();
                }, 1000);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  {
                    const data = this.state.data;
                    const db = firebase.firestore();
                    db.collection(
                      "APIIntegracionProveedoresDetalleConfiguracionServicio"
                    )
                      .doc(
                        this.state.codigo_empresa +
                          "_" +
                          this.state.codigo_proveedor +
                          "_" +
                          this.state.servicio +
                          "_" +
                          newData.parametro
                      )
                      .set({
                        codigo_empresa: this.state.codigo_empresa,
                        codigo_proveedor: this.state.codigo_proveedor,
                        nombre_empresa: this.state.nombre_empresa,
                        nombre_proveedor: this.state.nombre_proveedor,
                        servicio: this.state.servicio,
                        parametro: newData.parametro,
                        valor: newData.valor,
                        default: newData.default == null ? "" : newData.default,
                        descuento:
                          newData.descuento == null ? "" : newData.descuento,
                        portafolio:
                          newData.portafolio == null ? "" : newData.portafolio,
                      })
                      .then(function(result) {})
                      .catch(function(error) {});
                    this.setState({ data }, () => resolve());
                  }
                  resolve();
                }, 1000);
              }),

            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  const data = [...this.state.data];
                  data.splice(data.indexOf(oldData), 1);

                  const db = firebase.firestore();
                  db.collection(
                    "APIIntegracionProveedoresDetalleConfiguracionServicio"
                  )
                    .doc(
                      this.state.codigo_empresa +
                        "_" +
                        this.state.codigo_proveedor +
                        "_" +
                        this.state.servicio +
                        "_" +
                        oldData.parametro
                    )
                    .delete()
                    .then(function(result) {})
                    .catch(function(error) {});
                }, 600);
              }),
          }}
        /> */}

        <Grid>
          <Card
            style={{
              padding: 10,
              width: "100%",
            }}
          >
            <Box width="100%">
              <h4
                style={{
                  fontWeight: 500,
                }}
              >
                {tituloNombre.toUpperCase()}
              </h4>
              <DataGrid
                dataSource={this.state.data}
                keyExpr=""
                showColumnLines={true}
                // onRowClick={e => console.log(e)}
                // onCellPrepared={(e) => {
                //   e.cellElement.classList.add("adaptiveRowStyle");
                // }}
                onExporting={(e) => {}}
                showHeaderFilter={true}
                showRowLines={true}
                columnAutoWidth={true}
                showBorders={true}
                onRowRemoved={(row) => {
                  let oldData = row.data;
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      const data = [...this.state.data];
                      data.splice(data.indexOf(oldData), 1);
    
                      const db = firebase.firestore();
                      db.collection(
                        "APIIntegracionProveedoresDetalleConfiguracionServicio"
                      )
                        .doc(
                          this.state.codigo_empresa +
                            "_" +
                            this.state.codigo_proveedor +
                            "_" +
                            this.state.servicio +
                            "_" +
                            oldData.parametro
                        )
                        .delete()
                        .then(function(result) {})
                        .catch(function(error) {});
                    }, 600);
                  })
                }}
                onRowInserted={(row) => {
                  let newData = row.data;
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      {
                        const data = this.state.data;
                        const db = firebase.firestore();
    
                        db.collection(
                          "APIIntegracionProveedoresDetalleConfiguracionServicio"
                        )
                          .doc(
                            this.state.codigo_empresa +
                              "_" +
                              this.state.codigo_proveedor +
                              "_" +
                              this.state.servicio +
                              "_" +
                              newData.parametro
                          )
                          .set({
                            codigo_empresa: this.state.codigo_empresa,
                            codigo_proveedor: this.state.codigo_proveedor,
                            nombre_empresa: this.state.nombre_empresa,
                            nombre_proveedor: this.state.nombre_proveedor,
                            servicio: this.state.servicio,
                            parametro: newData.parametro,
                            valor: newData.valor,
                            default: newData.default == null ? "" : newData.default,
                            descuento:
                              newData.descuento == null ? "" : newData.descuento,
                            portafolio:
                              newData.portafolio == null ? "" : newData.portafolio,
                          })
                          .then(function(result) {})
                          .catch(function(error) {});
    
                        this.setState({ data }, () => resolve());
                      }
                      resolve();
                    }, 1000);
                  })
                }}
                onInitialized={() => {}}
                onRowUpdated={(row) => {
                  let newData = row.data;
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      {
                        const data = this.state.data;
                        const db = firebase.firestore();
                        db.collection(
                          "APIIntegracionProveedoresDetalleConfiguracionServicio"
                        )
                          .doc(
                            this.state.codigo_empresa +
                              "_" +
                              this.state.codigo_proveedor +
                              "_" +
                              this.state.servicio +
                              "_" +
                              newData.parametro
                          )
                          .set({
                            codigo_empresa: this.state.codigo_empresa,
                            codigo_proveedor: this.state.codigo_proveedor,
                            nombre_empresa: this.state.nombre_empresa,
                            nombre_proveedor: this.state.nombre_proveedor,
                            servicio: this.state.servicio,
                            parametro: newData.parametro,
                            valor: newData.valor,
                            default: newData.default == null ? "" : newData.default,
                            descuento:
                              newData.descuento == null ? "" : newData.descuento,
                            portafolio:
                              newData.portafolio == null ? "" : newData.portafolio,
                          })
                          .then(function(result) {})
                          .catch(function(error) {});
                        this.setState({ data }, () => resolve());
                      }
                      resolve();
                    }, 1000);
                  })
                }}
                rowAlternationEnabled={true}
              >
                <Editing
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  useIcons={true}
                  texts={texts2}
                >
                  <Popup
                    title="LISTADO DE PROVEEDORES"
                    showTitle={true}
                    width={700}
                    height={280}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item
                        dataField="parametro"
                        caption="Parámetro"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions1}
                      />
                      <Item
                        dataField="valor"
                        caption="Valor"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions2}
                      />
                      <Item dataField="default" caption="Valor Default" />
                    </Item>
                  </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <Column dataField="parametro" caption="Parámetro" />
                <Column dataField="valor" caption="Valor" />
                <Column dataField="default" caption="Valor Default" />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode="full"
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  texts={texts}
                />
              </DataGrid>
            </Box>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default ConfiguracionAvanzadaServicio;
