import React from "react";
import firestore from "firestore";
import firebase from "firebase/compat";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import sqlFormatter from "sql-formatter";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class DialogTransaccionesJson extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  onSubmit = (closeModal) => {

    closeModal();
  };

  render() {
    const { modalIsOpenReporte, closeModalReporte, rowData, title } = this.props;

    const styledata = { "font-size": "12px" };

    console.log("row data", rowData)

    return (
      <Dialog
        open={modalIsOpenReporte}
        onClose={closeModalReporte}
        aria-labelledby="form-dialog-title"
      >
        <LoadingOverlay
          active={this.state == null ? false : this.state.active}
          spinner={<BounceLoader />}
        >
          <DialogTitle
            style={{ backgroundColor: "#2ca8df", color: "white" }}
            id="form-dialog-title"
          >
            {title}
          </DialogTitle>

          <DialogContent dividers>
            <textarea
              rows="30"
              cols="80"
              id="query"
              // onKeyUp={this.handleChange.bind(this)}
              style={styledata}
              // onChange={this.handleChange.bind(this)}
              disabled
            >
              {JSON.stringify(rowData)}
            </textarea>
          </DialogContent>
          <DialogActions
            style={{ backgroundColor: "white", color: "white", padding: "0px" }}
          >
            <table style={{ width: "100%" }}>
              <tr>

                <td
                  align="center"
                  style={{ backgroundColor: "#6c7c84", color: "#26c6da" }}
                >
                  <Button
                    style={{ color: "white" }}
                    onClick={closeModalReporte}
                    color="primary"
                  >
                    Cerrar
                  </Button>
                </td>
              </tr>
            </table>
          </DialogActions>
        </LoadingOverlay>
      </Dialog>
    );
  }
}

export default DialogTransaccionesJson;
