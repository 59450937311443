import  React from "react";
import firestore from "firestore";
import firebase from "firebase/compat";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
 
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

class DialogProveedorDetalleRespuesta extends React.Component {

constructor (props) {
        super (props)       
}

render()
{
const {modalIsOpen, closeModal,responseServicio} = this.props;

console.log( this.props);
console.log("respuesta servicio : "+responseServicio);

 
    return(
        <Dialog open={modalIsOpen} onClose={closeModal} aria-labelledby="form-dialog-title">

 
        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">  Registro de Servicio </DialogTitle> 
  

        <DialogContent dividers>
          <DialogContentText> 
         <strong>   {responseServicio} </strong>
          </DialogContentText>  
        
        </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>
          <Button onClick={closeModal} color="primary">
            Cerrar
          </Button>
   

        </DialogActions>
      </Dialog>
    )
 }
}

export default DialogProveedorDetalleRespuesta;