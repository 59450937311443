import  React from "react";
import firebase from "firebase/compat";
import MaterialTable from "material-table";
import DialogPedidoJSON from "../Pedidos/DialogPedidoJSON";
import axios from "axios";

import LoadingOverlay from "react-loading-overlay";
import DialogReporte from "./DialogReporte";

class Pedidos extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
        data: [] ,
        modalIsOpen: false,
        modalIsOpen2: false,
        modalIsOpen3 : false,
        isEdited: false,
        rowData: {},
        isDeleted: false ,
        isActive :false ,
        rowList : []
      };

       const db = firebase.firestore();
        db.collection('APIPedidos')
        .onSnapshot((snapshot) => {

          let _data = []

          snapshot.docs.forEach(doc => {
            let dt = doc.data();
            dt["id"] = doc.id;
            _data.push( dt);
          });

          let data1 =[];
          _data.forEach(element => {

              let dataparsed = JSON.parse(element.pedido_enviado);

              var year = dataparsed.fecha_creacion.substring(0, 4);
              var month = dataparsed.fecha_creacion.substring(4, 6);
              var day = dataparsed.fecha_creacion.substring(6, 8);

              if(element.respuesta.includes("Pedido Procesado Exitosamente")  ||  element.respuesta.includes("Registro Procesado Exitosamente")   )
              {
                element["status"] =  "SINCRONIZADO";
              }else
              {
                element["status"] = "ERROR";
              }

              

              element["fecha_creacion"] =  year + '-' + month + '-' + day; 
              element["numero_pedido"] =  parseInt(dataparsed.numero_pedido);
              element["tipo_pedido"] =  dataparsed.tipo_pedido;
            data1.push(element);
          });

       

          this.setState({ columns: [
                            { title: 'Tipo Pedido', field: 'tipo_pedido' , editable: 'never' },
                            { title: 'Numero Pedido', field: 'numero_pedido' , editable: 'never' },
                            { title: 'id Cloud', field: 'id' , editable: 'never' },
                            { title: 'Fecha Pedido', field: 'fecha_creacion' , editable: 'never' ,defaultSort :"desc" },
                            { title: 'Estado Pedido', field: 'status' , editable: 'never' }
                           
                            
                                   ],
                          data: data1,
                          actions : [
                            {
                              icon: 'receipt',
                              tooltip: 'JSON Enviado',
                              onClick: (event,rowdata) =>  { 
                                this.setState({rowData :rowdata,modalIsOpen : true,isEdited :true});
                              }
                            },
                              {
                                icon: 'info',
                                tooltip: 'Respuesta Siesa',
                                onClick: (event,rowdata) =>  { 
                                  this.setState({rowData :rowdata,modalIsOpen : true,isEdited :false});
                                }

                            }, {
                              icon: 'sync_alt',
                              tooltip: 'Reenviar Pedido',
                              onClick: (event,rowdata) =>  { 
                                

                                let nro_doc = rowdata.id+"";
                                let those = this;
                                those.setState({isActive :true});
                                const _headers = {  
                                  'Content-Type': 'application/json', 
                                  'Accept': 'application/json', 
                                  'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
                                  'TROPI-TOKEN' : '65b4b563-9c4c-4694-b03e-8652e006e2d1'
                                }
                          
                                const urlData = "https://us-central1-gcp-tropi-gaussia.cloudfunctions.net/ReenviarPedidoSiesa?nro_doc="+nro_doc;
                                console.log(urlData);
                            
                                axios.get(urlData , {_headers : _headers})
                                .then((res) => {
                                 
                                  console.log(res.data);
                                  if (   res.data.ok.mensajes.length < 10
                                    ) {
                                      those.setState({
                                        rowList: res.data.ok.mensajes
                                      });
                                    } else {
                                      let dataJson = [];
                                      dataJson.push(
                                       res.data.ok.mensajes[0]
                                      );
                                      dataJson.push(
                                       res.data.ok.mensajes[1]
                                      );
                                      dataJson.push(
                                       res.data.ok.mensajes[2]
                                      );
                                      dataJson.push(
                                       res.data.ok.mensajes[3]
                                      );
                                      dataJson.push(
                                       res.data.ok.mensajes[4]
                                      );
                                      dataJson.push(
                                       res.data.ok.mensajes[5]
                                      );
                                      dataJson.push(
                                       res.data.ok.mensajes[6]
                                      );
                                      dataJson.push(
                                       res.data.ok.mensajes[7]
                                      );
                                      dataJson.push(
                                       res.data.ok.mensajes[8]
                                      );
                             
                                      those.setState({ rowList: dataJson });
                                    }
                             
                                    those.setState({ isActive: false });
                                    those.setState({ modalIsOpen3: true });

                                })
                                .catch((error) => {
                                    those.setState({isActive :false});
                                    console.log(error)
                                })        
                              }
                          }
                          ]
          });
        }, (error) => {
          console.log(error);
        });

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        const storedUsuario = localStorage.getItem("usuario");
        if (storedUsuario === null) {
           window.location.href = '/admin/dashboard'; 
        }
    }

    openModal() {
      this.setState({modalIsOpen: true});
    }
   
    afterOpenModal() {
      // references are now sync'd and can be accessed.
      this.subtitle.style.color = '#f00';
    }
   
    closeModal() {
      this.setState({modalIsOpen: false});
      this.setState({modalIsOpen3 :false})
    }   

render(){
 
  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

return (
            <div>
            <LoadingOverlay
            active={this.state.isActive}
            spinner
            text="Enviando  Informacion a Siesa, por favor espere unos momentos"
          >
              <MaterialTable
                  title="Listado de Pedidos"
                  columns={this.state.columns}
                  data={this.state.data}
                  actions={this.state.actions}
                  options={{
                    exportButton: true,
                    sorting :true,
                    exportAllData: true 
                  }}
                />
   
                </LoadingOverlay>
       <DialogPedidoJSON modalIsOpen={this.state.modalIsOpen}  closeModal={this.closeModal}   isEdited={this.state.isEdited}  rowData={this.state.rowData} />
       <DialogReporte
       modalIsOpen={this.state.modalIsOpen3}
       closeModal={this.closeModal}
       isEdited={this.state.isEdited}
       rowData={this.state.rowList}
       isDeleted={this.state.isDeleted}
       listadoEmpresas={this.state.listadoEmpresas}
     />

         
                 
            </div>
        );        
    }
}

export default Pedidos;