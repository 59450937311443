/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import DashboardPage from "views/Dashboard/Dashboard.js";
import IntegrarProveedorDetalle from "views/IntegrarProveedorDetalle/IntegrarProveedorDetalle.js";
import IntegrarProveedorDetalleReportes from "views/IntegrarProveedorDetalle/IntegrarProveedorDetalleReportes.js";
import IntegrarProveedor from "views/IntegrarProveedor/IntegrarProveedor.js";
import Empresas from "views/Empresa/Empresa.js";
import Usuarios from "views/Usuario/Usuario.js";
import Proveedores from "views/Proveedor/Proveedor.js";
import Reportes from "views/Reporte/Reporte.js";
import Servicios from "views/Servicio/Servicio.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Inicio",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/reportes",
    name: "Reportes",
    rtlName: "قائمة الجدول",
    icon: "list",
    component: Reportes,
    layout: "/admin"
  }
];

export default dashboardRoutes;
