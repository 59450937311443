import React from "react";
import firebase from "firebase/compat";
import MaterialTable from "material-table";
import DialogUsuario from "views/Usuario/DialogUsuario.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import { Box, Grid } from "@material-ui/core";
import "devextreme/dist/css/dx.light.css";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Export,
  Lookup,
  Editing,
  Item,
  Popup,
  Form,
  Selection,
  SearchPanel,
} from "devextreme-react/data-grid";
import Card from "components/Card/Card";

const allowedPageSizes = [5, 10, "Todos"];

const texts = {
  exportAll: "Exportar todos los datos",
  exportSelectedRows: "Exportar filas seleccionadas",
  exportTo: "Export",
};

const texts2 = {
  confirmDeleteMessage: "Estas seguro de eliminar el usuario?",
  cancelRowChanges: "Cancelar",
  saveRowChanges: "Guardar",
};
const valuesSelectRol = ["admin", "cartera", "costos", "reportes", "clientes"];

class Usuario extends React.Component {
  constructor(props) {
    super(props);

    console.log("STEP 1 - A ");

    this.state = {
      data: [],
      modalIsOpen: false,
      isEdited: false,
      rowData: {},
      isDeleted: false,
      empresas: [],
    };

    console.log("STEP 1 - B ");
    const db = firebase.firestore();

    db.collection("APIEmpresas").onSnapshot(
      (snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());
        let usuario = JSON.parse(localStorage.getItem("usuario"));
        let evaluar = _data.filter((e) => e.codigo === usuario.codigo_empresa);

        if (evaluar.length === 0) {
          this.setState({ empresas: _data });
        } else {
          this.setState({ empresas: evaluar });
        }
      },
      (error) => {
        console.log(error);
      }
    );

    db.collection("APIUsuarios").onSnapshot(
      (snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());
        let usuario = JSON.parse(localStorage.getItem("usuario"));
        let evaluar = this.state.empresas.filter(
          (e) => e.nombre === usuario.empresa
        );
        if (evaluar.length === 0) {
          this.setState({
            data: _data,
          });
        } else {
          let datos = _data.filter((e) => e.empresa === evaluar[0].nombre);
          this.setState({ data: datos });
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    const storedUsuario = localStorage.getItem("usuario");
    if (storedUsuario === null) {
      window.location.href = "/admin/dashboard";
    }
    console.log("STEP 1 - C ");
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    this.subtitle.style.color = "#ff0000";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  guardarData = (row) => {
    const db = firebase.firestore();
    db.collection("APIUsuarios")
      .doc(row.data.cedula)
      .set(row.data)
      .then(function(result) {
        // closeModal();
      })
      .catch(function(error) {});
  };

  borrarData = (row) => {
    try {
      const db = firebase.firestore();
      db.collection("APIUsuarios")
        .doc(row.data.cedula)
        .delete()
        .then(function(result) {})
        .catch(function(error) {});
    } catch (err) {}
  };

  render() {
    console.log("STEP 1 - D ");

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    let empresas = this.state.empresas.map((empresa) => empresa.nombre);

    let positionEditorOptions = {
      items: valuesSelectRol,
      searchEnabled: true,
      value: "",
    };

    let positionEditorOptions1 = {
      items: empresas,
      searchEnabled: true,
      value: "",
    };

    let ancho = window.screen.width;

    console.log("STEP 1 - E ");
    console.log("data", this.state.data);

    return (
      <div>
        <Grid>
          <Card
            style={{
              padding: 10,
              width: "100%",
            }}
          >
            <Box width="100%">
              <h4
                style={{
                  fontWeight: 500,
                }}
              >
                LISTADO DE USUARIOS
              </h4>
              <DataGrid
                dataSource={this.state.data}
                keyExpr="cedula"
                showColumnLines={true}
                // onRowClick={e => console.log(e)}
                onExporting={(e) => {}}
                showHeaderFilter={true}
                showRowLines={true}
                columnAutoWidth={true}
                showBorders={true}
                onRowRemoved={(row) => {
                  this.borrarData(row);
                }}
                onRowInserted={(row) => {
                  this.guardarData(row);
                }}
                onInitialized={() => {}}
                onRowUpdated={(row) => {
                  this.guardarData(row);
                }}
                rowAlternationEnabled={true}
              >
                <Editing
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  useIcons={true}
                  texts={texts2}
                >
                  <Popup
                    title="LISTADO DE USUARIOS"
                    showTitle={true}
                    width={ancho > 800 ? "700px" : ""}
                    height={ancho > 800 ? "320px" : ""}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item
                        dataField="cedula"
                        caption="Cédula"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="nombre"
                        caption="Nombre"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="clave"
                        caption="Clave"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="email"
                        caption="Email"
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="rol"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions}
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                      <Item
                        dataField="empresa"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions1}
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                    </Item>
                  </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <Column dataField="cedula" caption="Cédula" />
                <Column dataField="nombre" caption="Nombre" />
                <Column dataField="clave" caption="Clave" visible={false} />
                <Column dataField="email" caption="Email" />
                <Column dataField="rol" caption="Rol" />
                <Column dataField="empresa" caption="Empresa" />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode="full"
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  texts={texts}
                />
              </DataGrid>
            </Box>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default Usuario;
