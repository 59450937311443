import React from "react";
import firebase from "firebase/compat";
import MaterialTable from "material-table";
import DialogUsuario from "views/Usuario/DialogUsuario.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import DialogServicioQuery from "./DialogServicioQuery";

import { Box, Grid } from "@material-ui/core";
import "devextreme/dist/css/dx.light.css";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Export,
  Lookup,
  Editing,
  Item,
  Popup,
  Form,
  Selection,
  SearchPanel,
} from "devextreme-react/data-grid";
import Card from "components/Card/Card";

const allowedPageSizes = [5, 10, "Todos"];

const texts = {
  exportAll: "Exportar todos los datos",
  exportSelectedRows: "Exportar filas seleccionadas",
  exportTo: "Export",
};

const texts2 = {
  confirmDeleteMessage: "Estas seguro de eliminar el servicio?",
  cancelRowChanges: "Cancelar",
  saveRowChanges:"Guardar"
};

class Servicio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      modalIsOpen: false,
      isEdited: false,
      rowData: {},
      isDeleted: false,
    };

    const db = firebase.firestore();
    db.collection("APIServicios").onSnapshot(
      (snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());
        this.setState({
          columns: [
            { title: "Nombre", field: "nombre", editable: "never" },
            {
              title: "Tiempo Actualizacion",
              field: "tiempo",
              lookup: {
                0: "En Linea",
                1: "1 Minuto",
                2: "2 Minutos",
                3: "3 Minutos",
                4: "4 Minutos",
                5: "5 Minutos",
                10: "10 Minutos",
                15: "15 Minutos",
                30: "30 Minutos",
                45: "45 Minutos",
                60: "1 Hora",
                120: "2 Horas",
                720: "12 Horas",
                1440: "1 Dia",
              },
            },
          ],
          data: _data,
          actions: [
            {
              icon: "receipt",
              tooltip: "query",
              onClick: (event, rowdata) => {
                this.setState({ rowData: rowdata, modalIsOpen: true });
              },
            },
          ],
        });
      },
      (error) => {
        console.log(error);
      }
    );

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    const storedUsuario = localStorage.getItem("usuario");
    if (storedUsuario === null) {
      window.location.href = "/admin/dashboard";
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    console.log("data", this.state.data)

    return (
      <div>
        {/* <MaterialTable
          title="Listado de Servicios"
          columns={this.state.columns}
          data={this.state.data}
          actions={this.state.actions}
          options={{
            exportButton: true,
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  {
                    const data = this.state.data;
                    const index = data.indexOf(oldData);
                    data[index] = newData;

                    const db = firebase.firestore();
                    db.collection("APIServicios")
                      .doc(newData.nombre)
                      .set({
                        nombre: newData.nombre,
                        query: newData.query,
                        tiempo: newData.tiempo,
                      })
                      .then(function(result) {})
                      .catch(function(error) {});

                    this.setState({ data }, () => resolve());
                  }
                  resolve();
                }, 1000);
              }),
          }}
        />

        <DialogServicioQuery
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
          isEdited={this.state.isEdited}
          rowData={this.state.rowData}
        /> */}
        <Grid>
          <Card
            style={{
              padding: 10,
              width: "100%",
            }}
          >
            <Box width="100%">
              <h4
                style={{
                  fontWeight: 500,
                }}
              >
                LISTADO DE SERVICIOS
              </h4>
              <DataGrid
                dataSource={this.state.data}
                keyExpr="nombre"
                showColumnLines={true}
                // onRowClick={e => console.log(e)}
                onExporting={(e) => {}}
                showHeaderFilter={true}
                showRowLines={true}
                columnAutoWidth={true}
                showBorders={true}
                onRowRemoved={(row) => {}}
                onRowInserted={(row) => {}}
                onInitialized={() => {}}
                onRowUpdated={(row) => {}}
                rowAlternationEnabled={true}
              >
                <Editing
                  mode="popup"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  useIcons={true}
                  texts={texts2}
                >
                  <Popup
                    title="LISTADO DE SERVICIOS"
                    showTitle={true}
                    width={700}
                    height={220}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                    <Item dataField="nombre" caption="Nombre" />
                      <Item dataField="tiempo" caption="Tiempo actualización" />
 
                    </Item>
                  </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <Column dataField="nombre" caption="Nombre" />
                <Column dataField="tiempo" caption="Tiempo actualización" />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode="full"
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  texts={texts}
                />
              </DataGrid>
            </Box>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default Servicio;
