import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import firebase from "../../firestore";
import axios from "axios";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import UAParser from "ua-parser-js";
// @material-ui/icons
import FacebookLogin from "react-facebook-login";
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { bugs, website, server } from "variables/general.js";

import LoginModal from "./LoginForm";

import { facebookConfig, googleConfig } from "./socialconfig";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

let parser = new UAParser();
let usuarioParser = parser.getResult();

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    const storedUsuario = localStorage.getItem("usuario");
    if (storedUsuario === null) {
      this.state = {
        showModal: true,
        loggedIn: null,
        loading: false,
        error: null,
        initialTab: null,
        recoverPasswordSuccess: null,
        marketplaces: [],
        transaccionesRealizadas: [],
        transaccionesError: [],
        listadoServicios: [],
      };
    } else {
      this.state = {
        showModal: false,
        loggedIn: null,
        loading: false,
        error: null,
        initialTab: null,
        recoverPasswordSuccess: null,
        marketplaces: [],
        transaccionesRealizadas: [],
        transaccionesError: [],
        listadoServicios: [],
      };
    }

    const db = firebase.firestore();
    db.collection("APIProveedores").onSnapshot(
      (snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());
        this.setState({
          marketplaces: _data,
        });
      },
      (error) => {
        console.log(error);
      }
    );

    axios
    .get(
      "https://log.yubapps.com/api/YUBASyncAPI?nombre_coleccion=yubasync_transacciones&yuba_token=ALL&yuba_key=ALL"
    )
    .then((res) => {
      this.setState({ transaccionesRealizadas: res.data });
    });

    axios
    .get(
      "https://log.yubapps.com/api/YUBASyncAPI?nombre_coleccion=yubasync_transacciones_log&yuba_token=ALL&yuba_key=ALL"
    )
    .then((res) => {
      this.setState({ transaccionesError: res.data });
    });

    db.collection("APIServicios").onSnapshot(
      (snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());
        this.setState({
          listadoServicios: _data,
        });
      },
      (error) => {
        console.log(error);
      }
    );

  }

  toggleModal = () => {
    // this.setState({ showModal: !this.state.showModal });
  };

  saveDataDbCouch = () => {
    let usuarioLogin = JSON.parse(localStorage.getItem("usuario"));

    let fecha = new Date();

    let hora = fecha.toLocaleString();

    axios
      .post(" https://admin:123@log.yubapps.com/yubasync_log", {
        ...usuarioParser,
        usuario: usuarioLogin.nombre,
        fecha_hora: hora,
      })
      .then((json) => console.log("datosssssss", json));
  };

  handleLoginWithFacebook = () => {
    // Do something when 'Login with Facebook' is clicked
    let provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        let username = res.additionalUserInfo.profile.email;
        const db = firebase.firestore();
        let usuariosRef = db
          .collection("APIUsuarios")
          .where("email", "==", username);
        let query = usuariosRef.onSnapshot(
          (snapshot) => {
            const _data = snapshot.docs.map((doc) => doc.data());
            if (_data.length == 0) {
              this.setState({ showModal: true });
              localStorage.removeItem("usuario");
            } else {
              localStorage.setItem("usuario", JSON.stringify(_data[0]));
              this.setState({ showModal: false });
              this.saveDataDbCouch();
              // window.location.reload();
            }
            console.log(_data);
          },
          (error) => {
            console.log(error);
          }
        );
      });
    console.log("Login with Facebook...");
  };

  handleLoginWithGoogle = () => {
    // Do something when 'Login with Facebook' is clicked
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        let username = res.user.email;
        const db = firebase.firestore();
        let usuariosRef = db
          .collection("APIUsuarios")
          .where("email", "==", username);
        let query = usuariosRef.onSnapshot(
          (snapshot) => {
            const _data = snapshot.docs.map((doc) => doc.data());
            if (_data.length == 0) {
              this.setState({ showModal: true });
              localStorage.removeItem("usuario");
            } else {
              localStorage.setItem("usuario", JSON.stringify(_data[0]));
              this.setState({ showModal: false });
              this.saveDataDbCouch();
              // window.location.reload();
            }

            console.log(_data);
          },
          (error) => {
            console.log(error);
          }
        );
        console.log(res);
      });
    console.log("Login with Google...");
  };

  handleLoginWithMicrosoft = () => {
    console.log("Login con microsoft......");
    const provider = new OAuthProvider('microsoft.com');
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("result microsoft", result)
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
      })
      .catch((error) => {
        // Handle error.
      });
  };

  handleLoginByEmail = (username, password) => {
    // Do something when 'Signup by email' is clicked
    console.log("Sign up by email...___" + username + "___" + password);

    const db = firebase.firestore();
    let usuariosRef = db
      .collection("APIUsuarios")
      .where("email", "==", username)
      .where("clave", "==", password);
    let query = usuariosRef.onSnapshot(
      (snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());
        if (_data.length == 0) {
          this.setState({ showModal: true });
          localStorage.removeItem("usuario");
        } else {
          localStorage.setItem("usuario", JSON.stringify(_data[0]));
          this.setState({ showModal: false });

          this.saveDataDbCouch();
          // window.location.reload();
        }

        console.log(_data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    const loggedIn = this.state.loggedIn;
    const isLoading = this.state.loading;

    const labelsCustom = {
      loginTitle: "Ingreso",
      signupTitle: "Crear  Nuevo Usuario",
      forgotTitle: "Resetear Contraseña",
      loginFacebookButton: "Ingreso con Facebook",
      loginGoogleButton: "Ingreso con Google",
      loginMicrosoftButton: "Ingreso con Microsoft",
      loginEmailButton: "Ingresar",
      signupFacebookButton: "",
      signupGoogleButton: "",
      signupEmailButton: "",
      forgotButton: "",
      loginEmailPlaceholder: "Usuario",
      loginPasswordPlaceholder: "Clave",
      signupUsernamePlaceholder: "Usuario",
      signupLink: "",
      loginLink: "",
      forgotLink: "",
      orLabel: "o",
    };

    const textStyles = { color: "black" };

    const customUsernameRegex = /^[a-zA-Z0-9_]{5,}/;
    const classes = { cardCategory: "jss253", cardTitle: "jss255" }; // useStyles();

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>content_copy</Icon>
                </CardIcon>
                <p className={classes.cardCategory} style={textStyles}>
                  Proveedores configurados{" "}
                </p>
                <h3 className={classes.cardTitle} style={textStyles}>
                  {this.state.marketplaces.length}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Store />
                </CardIcon>
                <p className={classes.cardCategory} style={textStyles}>
                 Transacciones realizadas
                </p>
                <h3 className={classes.cardTitle} style={textStyles}>
                  {this.state.transaccionesRealizadas.length}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>info_outline</Icon>
                </CardIcon>
                <p className={classes.cardCategory} style={textStyles}>
                  Total novedades presentadas
                </p>
                <h3 className={classes.cardTitle} style={textStyles}>
                  {this.state.transaccionesError.length}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Accessibility />
                </CardIcon>
                <p className={classes.cardCategory} style={textStyles}>
                  Total servicios configurados
                </p>
                <h3 className={classes.cardTitle} style={textStyles}>
                  {this.state.listadoServicios.length}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="success">
                <ChartistGraph
                  className="ct-chart"
                  data={dailySalesChart.data}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle} style={textStyles}>
                  Estadisticas uso
                </h4>
                <p className={classes.cardCategory} style={textStyles}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                  </span>{" "}
                  incremento llamados por dia
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="warning">
                <ChartistGraph
                  className="ct-chart"
                  data={emailsSubscriptionChart.data}
                  type="Bar"
                  options={emailsSubscriptionChart.options}
                  responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                  listener={emailsSubscriptionChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Integraciones realizadas</h4>
                <p className={classes.cardCategory}>Estadisticas ultimo año</p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="danger">
                <ChartistGraph
                  className="ct-chart"
                  data={completedTasksChart.data}
                  type="Line"
                  options={completedTasksChart.options}
                  listener={completedTasksChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Transacciones Diarias</h4>
                <p className={classes.cardCategory}>Estadisticas ultimo dia</p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <LoginModal
          labels={labelsCustom}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          onLoginFacebook={this.handleLoginWithFacebook}
          onLoginEmail={this.handleLoginByEmail}
          onLoginGoogle={this.handleLoginWithGoogle}
          onLoginMicrosoft={this.handleLoginWithMicrosoft}
          usernameRegex={customUsernameRegex}
        />
      </div>
    );
  }
}

export default Dashboard;
